import { observer } from 'mobx-react-lite'
import { Icons, TypeIcon } from '../../../misc/Icons'
import { store } from '../../../stores/Store'
import NodeOptionsMenu from "../../../misc/NodeMenus"
import FolderPanelListItem from "./FolderPanelListItem"
import Panel, { PanelBody, PanelHeader, PanelLabel } from '../../layout/Panel'


export default observer(function FolderPanel () {

    var panelWidth = "270px"
    var {folderNode, getFolderChildren} = store.NodeStore
    var classes = store.NodeStore.loading ? "panel-folder disabled" : "panel-folder"
    var displayType = store.NodeStore.getDisplayType(folderNode?.typeName)

    return (
        <Panel index={0} className={classes} width={panelWidth} minimisable loadingContent={!folderNode}>

           <PanelLabel title="Folder panel" />
           
           <PanelHeader>
                <div style={{display: "grid", gap: "2px"}}>
                    <h4 className="trunc-ellipsis">{folderNode?.label}</h4>
                    <small style={{color: "grey"}}><TypeIcon node={folderNode} /> {displayType}</small>
                </div>
                
                <NodeOptionsMenu node={folderNode} />
            </PanelHeader>

            <PanelBody>
                <nav id="folder-panel-list">
                    {getFolderChildren?.length
                        ? getFolderChildren.map((node: any, i) => <FolderPanelListItem key={i} node={node} />)
                        : <small style={{margin: "10px auto", display: "flex", alignItems: "end", gap: "4px",}}>This {displayType} is empty, click <Icons.More style={{fontSize: "12px"}} /> to start adding things</small>}
                </nav>
            </PanelBody>

        </Panel>
    )
})


