import { Button } from "@mui/material";
import { AlertModal } from "../../../..";
import { observer } from "mobx-react-lite";
import { Theme } from "../../../../misc/Theme";
import { store } from "../../../../stores/Store";
import EnquiryUpdateSectionForm from "../../../form/enquiry/EnquiryUpdateSectionForm";


export default observer(function EnquirySettingsSection () {

    var {enquiry} = store.EnquiryStore
    var {sessionState} = store.AppStore

    const UpdateForm = () => {
        var settingsSection = enquiry.childrenObjects.find(s => s.name === "SETTINGS")
        if (settingsSection) {
            AlertModal({body: <EnquiryUpdateSectionForm enquiryUid={enquiry.uid} section={settingsSection} />})
        }
    }

    var settings = enquiry.childrenObjects.find(c => c.name === "SETTINGS")?.childrenObjects
    var sections = []

    // Restructure to list of sections
    settings.forEach(setting => {
        
        var existingSection = sections.find(ss => ss.category === setting.category)
        
        if (existingSection) {
            existingSection.rows.push(setting)
        } else {
            sections.push({
                label: setting.category.toLowerCase(),
                category: setting.category,
                rows: [setting]
            })
        }
    })

    return (
        <div className="detail-section">

            <div className="section-header">
                <div className="header-content">
                    <h5>Settings</h5>
                    {!sessionState ? <Button className="btn btn-xs" onClick={() => UpdateForm()}>Edit</Button> : null}
                </div>
            </div>

            {sections.map((section, i) => (
                <div key={i} className="section-table">
                    <div className="section-header section-subheader capitalise">
                        <h5>{section.label} settings</h5>
                    </div>
                    {section.rows?.map((settingNode, i) => (
                        <div key={i} className="section-row attribute-row">
                            <div className="section-cell">{settingNode.label}</div>
                            <div className="section-cell" style={{width: "160px", color: Theme.palette.secondary.main}}>
                                {settingNode.value.replace("true", "Yes").replace("false", "No")}
                            </div>
                        </div>
                    ))}
                </div>
            ))}
        </div>
    )
})