import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { DisplayDictionary } from "../../.."
import { store } from "../../../stores/Store"
import Account from "../../../models/Account"
import UserFormOnly from "./UserForms"

interface AccountEditFormProps {
    account: Account;
}

interface AccountCreateFormProps {
    parentUid: string;
}

export function AccountEditForm ({account}: AccountEditFormProps) {

    var initialValues = {
        "uid": account.uid,
        "accountName": account.accountName,
        "customerType": account.customerType,
        "addressLine1": account.addressLine1,
        "city": account.city,
        "postcode": account.postcode,
        "countryId": account.countryId,
    }

    const handleSubmit = async (values) => {

        return await Ajax.Node.Edit("account", values).then(() => {
            store.NodeStore.refreshFolderNode()
            store.AlertStore.Close()
        })
    }

    return <AccountFormOnly
        title="Edit Account"
        initialValues={initialValues}
        handleCancel={() => store.AlertStore.Close()}
        handleSubmit={(values) => handleSubmit(values)} />
}


export function AccountCreateForm ({parentUid}: AccountCreateFormProps) {

    const [step, setStep] = React.useState<0|1>(0)
    const [newAccount, setNewAccount] = React.useState<Account>(null)

    const handleSubmit = async (userValues) => {

        newAccount.childrenObjects = [{...userValues}]

        return await Ajax.Node.Create("account", parentUid, newAccount).then(() => {
            store.NodeStore.refreshFolderNode()
            store.AlertStore.Close()
        })
    }

    return (
        <div>
            <AccountFormOnly
                hidden={step !== 0}
                title="Create Account"
                handleCancel={() => store.AlertStore.Close()}
                handleSubmit={(values) => {
                    setNewAccount(values)
                    setStep(1)
                    return Promise.resolve()
                }} />

            <UserFormOnly
                hidden={step !== 1}
                includePassword={true}
                title="Create the first user of the new account"
                handleCancel={() => setStep(0)}
                handleSubmit={(values) => handleSubmit(values)} />
        </div>
    )
}


export default function AccountFormOnly ({
    title, 
    handleSubmit, 
    handleCancel, 
    initialValues = {
        "accountName": "",
        "customerType": "",
        "addressLine1": "",
        "city": "",
        "postcode": "",
        "countryId": "",
    }, 
    hidden = false
}) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var formik = useFormik({

        initialValues: initialValues,

        validationSchema: Yup.object({
            accountName: Yup.string().required("This field is required"),
            customerType: Yup.string().required("This field is required"),
            addressLine1: Yup.string().required("This field is required"),
            city: Yup.string().required("This field is required"),
            postcode: Yup.string().required("This field is required"),
            countryId: Yup.string().required("This field is required"),
        }),

        onSubmit: async (values) => {

            setError("")
            setSubmitting(true)

            return await handleSubmit(values).catch((response) => {

                setError(response.data?.detail ? response.data?.detail : "Something went wrong!")
                response.errors && formik.setErrors(response.errors)

            }).finally(() => setSubmitting(false))

        }
    })

    if (hidden) return null

    return (
        <form autoComplete="off" className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>

            <div className="form-header">
                <h3>{title}</h3>
            </div>

            <div className="form-fields" style={{maxHeight: "60vh", overflowY: "scroll"}}>
                <Field.Text
                    formik
                    autoFocus={true}
                    label="Account name"
                    name="accountName"
                    value={formik.values.accountName}
                    warning={formik.submitCount && formik.errors.accountName ? formik.errors.accountName : false}
                    onChange={formik.handleChange} />

                <Field.Select
                    formik
                    label="Customer type"
                    name="customerType"
                    value={formik.values.customerType}
                    warning={formik.submitCount && formik.errors.customerType ? formik.errors.customerType : false}
                    onChange={formik.handleChange}
                    options={DisplayDictionary("customer_type", true)}
                />

                <Field.Text
                    formik
                    label="Address"
                    name="addressLine1"
                    value={formik.values.addressLine1}
                    warning={formik.submitCount && formik.errors.addressLine1 ? formik.errors.addressLine1 : false}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    label="City"
                    name="city"
                    value={formik.values.city}
                    warning={formik.submitCount && formik.errors.city ? formik.errors.city : false}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    label="Postcode"
                    name="postcode"
                    value={formik.values.postcode}
                    warning={formik.submitCount && formik.errors.postcode ? formik.errors.postcode : false}
                    onChange={formik.handleChange} />

                <Field.MinimisedLargeListSelect
                    formik
                    multiple={false}
                    label="Country"
                    name="countryId"
                    value={formik.values.countryId}
                    warning={formik.submitCount && formik.errors.countryId ? formik.errors.countryId : true}
                    onChange={formik.handleChange}
                    options={DisplayDictionary("countries", true)}
                />
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => handleCancel()}>
                    Cancel
                </Button>
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>

        </form>
    )
}