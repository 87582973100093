import Ajax from '../Ajax'
import { makeAutoObservable } from 'mobx'
import { Alert } from '..'
import { format } from 'date-fns'


export class MaintStore {

    loading: boolean = true

    // ten mins
    memory: number = 0
    time: string = ""
    session: number = 0
    postgres: number = 0
    cpu: number = 0

    // one day
    avMemory: number = 0
    avTime: string = ""
    avSession: number = 0
    avPostgres: number = 0
    avCpu: number = 0

    // one year
    yearMemory: number = 0
    yearTime: string = ""
    yearSession: number = 0
    yearPostgres: number = 0
    yearCpu: number = 0

    constructor () {
        makeAutoObservable(this)
    }

    getPolling = async () => {
        
        this.loading = true

		var response: any = await Ajax.Maint.GetPolling().catch((response) => {
            Alert({message: response.data?.detail})
        })

        this.loading = false

        const c = JSON.parse(JSON.stringify(response.data))
        const t = c.map((avTime) => avTime[10])
        const sess = c.map((avTime) => avTime[4])
        const pgs = c.map((avTime) => avTime[5])
        const cp = c.map((avTime) => avTime[7])
        this.time = t.sort().map((time) => format(new Date(time), "HH:mm:ss"))
        this.memory = c.map((memory) => memory[6])
        this.session = this.sortArrays(t, sess)[1]
        this.postgres = this.sortArrays(t, pgs)[1]
        this.cpu = this.sortArrays(t, cp)[1]
	}

    getPollingDaily = async () => {

        this.loading = true

		var response: any = await Ajax.Maint.GetPollingDaily().catch((response) => {
            Alert({message: response.data?.detail})
        })
        
        this.loading = false

        const c = JSON.parse(JSON.stringify(response.data))
        const time = c.map((avTime) => avTime[10])
        const session = c.map((avTime) => avTime[4])
        const postgres = c.map((avTime) => avTime[5])
        const cpu = c.map((avTime) => avTime[7])
        this.avTime = time.sort().map((time) => format(new Date(time), "yyyy-MM-dd"))
        this.avMemory = c.map((avMemory) => avMemory[6])
        this.avSession = this.sortArrays(time, session)[1]
        this.avPostgres = this.sortArrays(time, postgres)[1]
        this.avCpu = this.sortArrays(time, cpu)[1]
	}

    getPollingYearly = async () => {

        this.loading = true

		var response: any = await Ajax.Maint.GetPollingYearly().catch((response) => {
            Alert({message: response.data?.detail})
        })

        this.loading = false
        
        const c = JSON.parse(JSON.stringify(response.data))
        const time = c.map((avTime) => avTime[10])
        const session = c.map((avTime) => avTime[4])
        const postgres = c.map((avTime) => avTime[5])
        const cpu = c.map((avTime) => avTime[7])
        this.yearTime = time.sort().map((time) => format(new Date(time), "yyyy-MM-dd"))
        this.yearMemory = c.map((avMemory) => avMemory[6])
        this.yearSession = this.sortArrays(time, session)[1]
        this.yearPostgres = this.sortArrays(time, postgres)[1]
        this.yearCpu = this.sortArrays(time, cpu)[1]
	}



    sortArrays = (arrays, comparator = (a, b) => (a < b) ? -1 : (a > b) ? 1 : 0) => {
        let arrayKeys = Object.keys(arrays);
        let sortableArray = Object.values(arrays)[0];
        let indexes = Object.keys(sortableArray);
        let sortedIndexes = indexes.sort((a, b) => comparator(sortableArray[a], sortableArray[b]));

        let sortByIndexes = (array, sortedIndexes) => sortedIndexes.map(sortedIndex => array[sortedIndex]);

        if (Array.isArray(arrays)) {
            return arrayKeys.map(arrayIndex => sortByIndexes(arrays[arrayIndex], sortedIndexes));
        } else {
            let sortedArrays = {};
            arrayKeys.forEach((arrayKey) => {
                sortedArrays[arrayKey] = sortByIndexes(arrays[arrayKey], sortedIndexes);
            });
            return sortedArrays;
        }
    }

}

