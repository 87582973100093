import { useEffect } from 'react'
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons'
import { store } from '../../../stores/Store'
import { IconButton } from "@mui/material"
import Panel, { PanelBody, PanelHeader, PanelLabel } from '../../layout/Panel'
import Field from '../../form/fields/Field'
import { DisplayValue } from '../../..'


export default observer(function ReportMgmtPanel () {

    const { ReportStore } = store
    const { getReports, reports, filterReports, loading } = ReportStore

    const filteredReports = filterReports(reports)

    var title = `${loading ? "Loading" : reports?.length} Reports`
    if (!loading && filteredReports?.length < reports?.length) {
        title = `${filteredReports?.length} of ${title}`
    }

    return (
        <Panel index={0} minimisable width="250px">

            <PanelLabel title="Filters" />

            <PanelHeader>

                <h4>{title}</h4>
                <IconButton onClick={getReports}><Icons.Refresh style={{fontSize: "16px"}} /></IconButton>

            </PanelHeader>

            <PanelBody>
                <div style={{marginTop: "10px"}}>
                    <Field.Select
                        formik
                        label="Order by"
                        value={ReportStore.orderedBy}
                        onChange={(e) => {
                            ReportStore.orderedBy = e.target.value
                        }}
                        options={[
                            {label: DisplayValue("label", "Subject"), value: "subject"},
                            {label: DisplayValue("label", "Family"), value: "case"},
                            {label: DisplayValue("label", "Account"), value: "account"},
                            {label: DisplayValue("label", "Requested by"), value: "requestedBy"},
                            {label: DisplayValue("label", "Report type"), value: "reportType"},
                            {label: DisplayValue("label", "Legal system"), value: "legalSystem"},
                            {label: DisplayValue("label", "Nationality"), value: "statusId"},
                            {label: DisplayValue("label", "File"), value: "fileStatus"},
                            {label: DisplayValue("label", "RLID"), value: "reportLanguageId"},
                            {label: DisplayValue("label", "Version"), value: "version"},
                            {label: DisplayValue("label", "Requested"), value: "requestedAt"},
                            {label: DisplayValue("label", "Valid"), value: "valid"},
                        ]} />

                    <Field.Select
                        formik
                        label="Order"
                        value={ReportStore.order}
                        onChange={(e) => {
                            ReportStore.order = e.target.value
                        }}
                        options={[
                            {label: "Descending", value: "desc"},
                            {label: "Ascending", value: "asc"},
                        ]} />

                    <Field.Text
                        formik
                        label="Search"
                        value={ReportStore.searchString}
                        onChange={(e) => {
                            ReportStore.searchString = e.target.value
                        }}/>
                </div>
            </PanelBody>

        </Panel>
    )
})