import { Button } from "@mui/material"
import { AlertModal } from "../.."
import { Icons } from "../../misc/Icons"
import AbsenceForm from "./AbsenceForm"
import Field from "./fields/Field"
import ServerSideSearchField from "./fields/ServerSideSearchField"


export default function InformationRequestField ({field, formik, certainty, index}) {

    var autoFocus = index === 0 && window.innerWidth > 600 ? true : false

    switch (field.style) {

        // TEXT fields
        case "TEXTBOX":

            if (field.label === "ABSENCE") {

                if (field.enteredValuesObj.length === 0) return <>Error: No absence block!</>

                var absenceBlock = JSON.parse(field.enteredValuesObj[0])
                const showAbsenceForm = () => AlertModal({body: <AbsenceForm absenceBlock={absenceBlock} />, width: "750px", id: "ABSENCE"})
                showAbsenceForm()
                
                return (
                    <Button variant="contained" className="btn btn-sm" onClick={showAbsenceForm}>Open absence data form</Button>
                )
            }

            return (
                <Field.Text
                    formik
                    label={field.label}
                    autoFocus={autoFocus}
                    name={index.toString()}
                    onChange={formik.handleChange}
                    value={formik.values[index.toString()]}
                    type={field.datatype === "System.Int32" ? "number" : "text"}
                    disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                    warning={formik.submitCount ? formik.errors[index.toString()] : null} />
            )

        // DATE Fields
        case "DATE":
            return (
                <div style={{display: "flex", alignItems: "end"}}>
                    <Field.Date
                        formik
                        label={field.label}
                        autoFocus={autoFocus}
                        name={index.toString()}
                        onChange={formik.handleChange} 
                        minDate={new Date(field.minDate)}
                        maxDate={new Date(field.maxDate)}
                        openDateCheckbox={field.hasSecondaryCheckbox ? {label: field.secondaryCheckboxLabel, value: field.secondaryCheckboxValues[0]} : null}
                        value={formik.values[index.toString()]}
                        disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                        warning={formik.submitCount ? formik.errors[index.toString()] : null} />
                </div>
            )

        // SELECT fields
        case "DROPDOWN":
            return (
                <Field.Select
                    formik
                    label={field.label}
                    autoFocus={autoFocus}
                    name={index.toString()}
                    onChange={formik.handleChange}
                    options={field.availableValues}
                    overrideEnter={formik.handleSubmit}
                    value={formik.values[index.toString()]}
                    disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                    warning={formik.submitCount ? formik.errors[index.toString()] : null} />
            )

        case "LISTBOX":

            const onSearchSubmit = (option) => {
                formik.handleChange({target: {name: index.toString(), value: [option.value]}})
                var existingOption = field.availableValues.find(o => o.value === option.value)
                if (!existingOption) {
                    field.availableValues.push(option)
                }
            }

            return (
                <div>
                    <Field.LargeListSelect
                        formik
                        label={field.label}
                        autoFocus={autoFocus}
                        name={index.toString()}
                        noneOption={field.minValueCount === 0}
                        onChange={formik.handleChange}
                        options={field.availableValues}
                        minValueCount={field.minValueCount}
                        maxValueCount={field.maxValueCount}
                        value={formik.values[index.toString()]}
                        disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                        warning={formik.submitCount ? formik.errors[index.toString()] : null} />
                    
                    {field.searchFacility === "TERRITORY" ? (
                        <Button 
                            className="btn btn-xs" 
                            style={{margin: "4px 0"}}
                            onClick={() => {
                                AlertModal({body: <ServerSideSearchField handleSubmit={onSearchSubmit} returnOption />})
                            }}>
                            <Icons.TerritoryFinder />
                            Territory finder
                        </Button>
                    ) : null}
                </div>
            )
            
        case "RADIO":
            return (
                <Field.Radio
                    formik
                    label={field.label}
                    autoFocus={autoFocus}
                    name={index.toString()}
                    onChange={formik.handleChange}
                    options={field.availableValues}
                    value={formik.values[index.toString()]}
                    disabled={formik.isSubmitting || ["UNKNOWN", "TOFOLLOW"].includes(certainty)}
                    warning={formik.submitCount ? formik.errors[index.toString()] : null} />
            )

        default:
            return <p>Unrecognised field</p>
    }
}