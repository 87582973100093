import { observer } from "mobx-react-lite"
import { useStore } from "../../../../stores/Store"
import Panel, { PanelLabel, PanelHeader, PanelBody } from "../../../layout/Panel"


export default observer(function ErrorDetail () {  

    const {NodeStore} = useStore()
    var node = NodeStore.leafNode

    return (
        <Panel index={2} className="detail" width="100%" loadingContent={NodeStore.loadingLeafNode}>

            <PanelLabel title="Error detail" />

            <PanelHeader>
                <h4>{node.label ? node.label : "Something went wrong there"}</h4>
            </PanelHeader>

            <PanelBody>
                <p>{node.detail ? node.detail : "We couldn't get the resource your are looking for."}</p>
            </PanelBody>

        </Panel>
    )
})