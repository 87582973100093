import { ReactElement } from 'react';
import { Button } from '@mui/material';
import { Link } from 'react-router-dom';
import { Icons } from '../../misc/Icons';
import { store } from '../../stores/Store';
import { observer } from 'mobx-react-lite';


interface ViewBannerProps {
    title: string | ReactElement
}


export default observer(function ViewBanner ({title}: ViewBannerProps) {

    var {sessionState, stateSubject, stateAction} = store.AppStore
    var linkBackTo = `/app/main/${store.NodeStore.folderNode?.uid}/${stateSubject}`

    var showLinkBackTo = sessionState && ["Editing", "Running"].includes(stateAction) && !window.location.href.includes(stateSubject)

    return (        
        <div className="view-banner">

            <div className="title">
                <small>{title}</small>
            </div>

            <div style={{display: "flex", gap: "10px", alignItems: "center"}}>
                {showLinkBackTo 
                    ? <Button className="btn btn-xs" variant="contained" color="primary" component={Link} to={linkBackTo}>Back to {stateSubject?.replace("_", " ")}</Button>
                    : null}

                <a href="https://file.aoralaw.com/images/Aora ND User Guide WEB v1.1.pdf" target="_blank" rel="noreferrer">
                    <Button className="btn btn-sm">
                        <Icons.Info />
                    </Button>
                </a>
            </div>
            
        </div>
    )
})