import Ajax from '../../../Ajax';
import Help from '../../../misc/Help';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons';
import { store, useStore } from '../../../stores/Store';
import PersonCreateForm from '../../form/entity/PersonCreateForm';
import Panel, { PanelBody, PanelHeader, PanelLabel } from '../../layout/Panel';
import Field from '../../form/fields/Field';
import React from 'react';


export default observer(function QuestionPanel () {

    const {informationRequest, setInformationRequest} = store.TaxNodeStore

    return (
        <Panel
            index={1}
            minimisable
            className="panel-question"
            hidden={!informationRequest}
            // loadingContent={!informationRequest}
            width={!informationRequest ? "0px" : "270px"} >

            <PanelLabel title="Information request" />

            <PanelHeader>
                <h4 style={{textTransform: "capitalize"}}>Question</h4>

                <Button
                    className="btn btn-sm"
                    aria-label="Pause enquiry"
                    onClick={() => setInformationRequest()}>
                    <Icons.X />
                </Button>
            </PanelHeader>

            <PanelBody>

                {informationRequest ? <InformationRequestForm /> : null}
                
            </PanelBody>

        </Panel>
    )
})


function InformationRequestForm () {

    const {informationRequest, setInformationRequest} = store.TaxNodeStore

    const [entityA, setEntityA] = React.useState(null)
    const [entityB, setEntityB] = React.useState(null)
    const [relationship, setRelationship] = React.useState(null)

    var entities = store.TaxNodeStore.folderNode?.childrenObjects?.filter((node) => {
        return node.uid.includes("COMPANY")
        // return true
    })

    var entityOptions = [...entities.map(node => {
        return {label: node.label, value: node.uid}
    })]

    var relOptions = Object.values(store.TaxNodeStore.relationshipDisplayDict).map((value) => {
        return {label: value, value: value}
    })    
    
    return (
        <form autoComplete="off" className="from form-information-request">
            <div className="question-details">
                <small className="title">{informationRequest?.statement}</small>
                <h5>{informationRequest?.title}</h5>
            </div>
            <div className="form-fields">
                <Field.Select formik label="Entity A" value={entityA} options={entityOptions} onChange={(e) => setEntityA(e.target.value)} />
                <Field.Select formik label="Entity B" value={entityB} options={entityOptions} onChange={(e) => setEntityB(e.target.value)} />
                <Field.Select formik label="Relationship" value={relationship} options={relOptions} onChange={(e) => setRelationship(e.target.value)} />
                <Field.Text label="Additional details" />
            </div>
            <Button className="btn btn-sm" variant="contained" onClick={() => setInformationRequest(null)}>Submit</Button>
        </form>
    )
}