import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"
import { User } from "../../../models/User"


interface CaseEditFormProps {
    caseNode: any;
}

interface CaseCreateFormProps {
    parentUid: string;
}

export function CaseEditForm ({caseNode}: CaseEditFormProps) {
    return <CaseFormOnly 
        initialValues={{
            ...caseNode,
            ownerUid: caseNode.owner.uid
        }} 
        title="Edit case"
        showOwnerSelect={true}
        handleCancel={() => store.AlertStore.Close()}
        handleSubmit={(caseNode) => Ajax.Node.Edit("case", caseNode)} 
    />    
}

export function CaseCreateForm ({parentUid}: CaseCreateFormProps) {

    // parentUid will be "FOLDER_{userId}"
    var perentUserUid = parentUid.replace("FOLDER", "USER")

    return <CaseFormOnly 
        initialValues={{
            name: "",
            ownerUid: perentUserUid
        }} 
        title="Edit case"
        handleCancel={() => store.AlertStore.Close()}
        handleSubmit={ async (caseNode) => {
            var ownerResult = await Ajax.Node.Get(perentUserUid)
            var owner: User = ownerResult.data
            return await Ajax.Node.Create("case", owner.account.uid, caseNode)
        }}
    />    
}


export default function CaseFormOnly ({
    title,
    handleCancel, 
    handleSubmit,
    showOwnerSelect = false,
    initialValues = {
        "name": "",
        "ownerUid": ""
    }
}) {

    const [users, setUsers] = React.useState([])
    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    React.useEffect(() => {
        Ajax.Node.Get(store.AppStore.sessionInfo.parentAccount.uid).then((response) => {
            var users = response.data.childrenObjects.filter((child) => child.typeName === "Aora.Platform.Data.UserNode")
            setUsers(users)
        })
    }, [])

    var formik = useFormik({

        initialValues: initialValues,

        validationSchema: Yup.object({
            name: Yup.string().required("You must prvide a name")
        }),

        onSubmit: async (values) => {
            
            setError("")
            setSubmitting(true)

            // Convert values back to a proper CaseNode
            var caseNode = {...values, owner: {"uid": values.ownerUid}}
            delete caseNode.ownerUid

            return await handleSubmit(caseNode).then(({data}) => {
            
                setSubmitting(false)
                store.NodeStore.refreshFolderNode()
                store.AlertStore.Close()
            
            }).catch((response) => 
                setError(response.data?.detail))
            .finally(() => 
                setSubmitting(false))
        }
    })

    if (!users.length) {
        return <h4>Loading options...</h4>
    }

    return (
        <form className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>

            <div className="form-header">
                <h3>{title}</h3>
            </div>

            <div className="form-subheader">
                <p>The case's name is often the family name of the subject</p>
            </div>

            <div className="form-fields">
                <Field.Text
                    formik
                    autoFocus
                    label="Case name"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    warning={formik.submitCount ? formik.errors.name : ""}
                />

                {showOwnerSelect && <Field.Select 
                    formik
                    label="Owner"
                    name="ownerUid"
                    value={formik.values.ownerUid}
                    onChange={formik.handleChange}
                    warning={formik.submitCount ? formik.errors.ownerUid : ""}
                    options={users.map(user => {
                        return {label: user.label, value: user.uid}
                    })}
                />}
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    onClick={() => handleCancel()}
                    disabled={formik.isSubmitting}
                    className="btn btn-sm">
                    Cancel
                </Button>
                
                <Button
                    type="submit"
                    variant="contained"
                    disabled={formik.isSubmitting}
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>

        </form>
    )
}

