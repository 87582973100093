import MainMenu from './MainMenu'
import Logo from '../../misc/Logo'
import { Link } from 'react-router-dom'
import { observer } from 'mobx-react-lite'
import { store } from '../../stores/Store'
import { Button, Toolbar } from '@mui/material'
import SystemInformation from '../modals/SystemInformation'


export default observer(function PrimarySearchAppBar() {

    return (
        <Toolbar className="nav-header">
            {store.AppStore.isLoggedIn && <MainMenu />}

            <Button component={Link} aria-label='Home' color="info" className="logo-button" to="/app/main"><Logo color="white" type="block" /></Button>

            <SystemInformation key={store.ConnectionStore.connectionState} />
        </Toolbar>
    )
})