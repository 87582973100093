import { Button } from "@mui/material"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { store } from "../../../../stores/Store"
import { AlertModal, DisplayValue } from "../../../.."
import NodeOptionsMenu from "../../../../misc/NodeMenus"
import Panel, { PanelLabel, PanelHeader, PanelBody } from "../../../layout/Panel"
import { UserUpdateForm } from "../../../form/admin/UserForms"


export default observer(function EntityDetail () {

    const user = store.NodeStore.leafNode

    return (
        <Panel index={2} className="detail" width="100%" loadingContent={store.NodeStore.loadingLeafNode}>

            <PanelLabel title="Person details" />

            <PanelHeader>

                <div style={{display: "flex", gap: "20px"}}>
                    <h4>{user.label}</h4>
                    {store.AppStore.checkJwtRole("account_manager")
                        ? <Button className="btn btn-xs" variant="contained" onClick={() => AlertModal({body: <UserUpdateForm user={user} />})}>Edit user</Button>
                        : null}
                </div>

                <NodeOptionsMenu node={user} />

            </PanelHeader>

            <PanelBody>
                <div className="panel-columns">
                    <div className="panel-column">

                        <div className="detail-section">
                            <div className="section-table">
                                <div className="section-header">
                                    <h5>Basic details</h5>
                                </div>

                                <UserDetailRow label="First name" value={user.firstName} />
                            
                                <UserDetailRow label="Family name" value={user.familyName} />
                            
                                <UserDetailRow label="Title" value={DisplayValue("title", user.title)} />
                            
                                <UserDetailRow label="Username" value={user.username} />
                            
                                <UserDetailRow label="Email" value={user.email} />
                            
                                <UserDetailRow label="Phone" value={user.phone} />
                            
                                <UserDetailRow label="Unique ID" value={user.uid} />

                                <UserDetailRow label="Account" value={<Link className="link" to={`/app/main/${user.account.uid}`} >{user.account.name}</Link>} />
                                    
                                <UserDetailRow label="Is account manager" value={user.isAccountManager ? "Yes" : "No"} />
                                
                                <UserDetailRow label="Active" value={user.active ? "Yes" : "No"} />

                                <UserDetailRow label="Open enquiries" value={user.openEnquiries} />
                            
                                <UserDetailRow label="Closed enquiries" value={user.closedEnquiries} />
                            
                                <UserDetailRow label="Reports ordered" value={user.reportsRequested} />
                                
                            </div>
                        </div>
                        <div className="detail-section">
                            <Button component={Link} to={`/app/main/${user.uid.replace("USER", "FOLDER")}`} className="btn btn-sm" color="primary" variant="contained">Go to working folder</Button>
                        </div>
                    </div>

                    <div className="panel-column"></div>
                </div>
            </PanelBody>

        </Panel>
    )
})

function UserDetailRow ({label, value}) {

    // The server won't provide these fields to non-account managers
    var hiddenFields = ["Title", "Username", "Email", "Phone", "Is account manager", "Active"]

    if (!store.AppStore.checkJwtRole("account_manager") && hiddenFields.includes(label)) {
        return <></>
    }

    return (
        <div className="section-row attribute-row">
            <div className="section-cell">{label}</div>
            <div className="section-cell">{value}</div>
        </div>
    )
}