import React from 'react';
import { Line } from "react-chartjs-2";
import { observer } from 'mobx-react-lite'
import { store } from '../../../stores/Store'
import { format } from 'date-fns';


export default observer(function TenMins() {
    
    const { loading, memory, time, postgres, session, cpu, getPolling } = store.MaintStore

    React.useEffect(() => {
        getPolling()
    }, [])

    var labels = time;

    const data = {
        labels: labels,
        datasets: [
            {
                label: "memory usage",
                backgroundColor: "rgb(100, 99, 132)",
                borderColor: "rgb(100, 99, 132)",
                // data: [connections[0]],
                fill: false,
                data: memory,
            },
            {
                label: "cpu usage",
                backgroundColor: "rgb(10, 99, 132)",
                borderColor: "rgb(10, 99, 132)",
                // data: [connections[0]],
                fill: false,
                data: cpu,
            },
        ],
    };

    const data_session = {
        labels: labels,
        datasets: [
            {
                label: "open sessions",
                backgroundColor: "rgb(60, 70, 200)",
                borderColor: "rgb(60, 70, 200)",
                fill: false,
                data: session
            },
            {
                label: "postgres connections",
                backgroundColor: "rgb(100, 30, 100)",
                borderColor: "rgb(100, 30, 100)",
                fill: false,
                data: postgres
            }
        ]
    };

    const options = {
        scales: {
            y: {
                min: 0.00,
                max: 100.00,
                title: {
                    display: true,
                    text: 'Percentage Used',
                    font: {
                        size: 15,
                        family: 'sans-serif',
                    },
                },

            },
            x: {
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: 15,
                        family: 'sans-serif'
                    }
                }
            }

        },
        plugins: {
            sort: {
                enable: true
            }
        }
    };

    const options_session = {
        scales: {
            y: {
                min: 0.00,
                title: {
                    display: true,
                    text: 'Open Sessions',
                    font: {
                        size: 15,
                        family: 'sans-serif',
                    },
                },
            },
            x: {
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: 15,
                        family: 'sans-serif'
                    }
                }
            }

        }
    };

    if (loading) return <p>Loading...</p>

    return (
        <div>
            <Line data={data} options={options} />
            <Line data={data_session} options={options_session} />
        </div>
    )

})