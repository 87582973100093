import React from "react"
import Ajax from "../../../Ajax"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { store } from "../../../stores/Store"


export default function CaseDeleteForm ({node}) {

    const [labelConfirmation, setLabelConfirmation] = React.useState("")
    const [success, setSuccess] = React.useState(null)
    const [errorMessage, setErrorMessage] = React.useState(false)

    const deleteCase = async () => {

        await Ajax.Node.Delete(node.uid).then(() => {
            var breadcrumb = store.NodeStore.breadcrumb.filter(nodeRef => nodeRef.uid !== node.uid)
            store.NodeStore.navigateNode(breadcrumb[breadcrumb.length-1].uid)
            setSuccess(true)
        }).catch((response) => {
            var message = response.data?.detail ? response.data?.detail : "Something went wrong"
            setErrorMessage(message)
        })    
    }

    if (success) return <h4>This case was successfully deleted</h4>

    return (
        <form className="form" onSubmit={() => deleteCase()}>
            <div className="form-header">
                <h3>Delete case</h3>
            </div>

            <div className="form-fields">
                <p>
                    Please type "{node.label}" below to confirm that you want to permanently 
                    delete this case and all of the entities and enquiries it contains.
                </p>
                <Field.Text 
                    formik 
                    label="Delete confirmation" 
                    value={labelConfirmation} 
                    onChange={(e) => setLabelConfirmation(e.target.value)} 
                    warning={errorMessage} />
            </div>

            <div className="form-buttons">
                <Button className="btn btn-sm" variant="contained" color="success">Cancel</Button>
                <Button
                    onClick={deleteCase}
                    className="btn btn-sm"
                    variant="contained"
                    color="error"
                    disabled={node.label !== labelConfirmation}>
                    Delete
                </Button>
            </div>
        </form>
    )
}