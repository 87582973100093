
import {
    Drawer,
    IconButton,
    Button
} from '@mui/material'
import Logo from '../../misc/Logo';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { Icons } from '../../misc/Icons';
import { store } from '../../stores/Store';
import { AlertModal, history } from '../..';
import TicketRequestForm from '../form/TicketRequestForm';


export default function MainMenu() {

    const [open, setOpen] = useState(false)

    history.listen((location, action) => {
        setOpen(false)
    })

    document.onkeyup = function(e) {
        if (e.ctrlKey && e.key === "m") {
            setOpen(!open)
        }
    }

    return (
        <React.Fragment>

            <IconButton
                onClick={() => {setOpen(true)}}
                color="inherit"
                aria-label="Main menu"
                className="main-menu-toggler"
            >
                <Icons.Menu color="info" />
            </IconButton>

            <Drawer
                anchor="left"
                open={open}
                role="menu"
                onClose={() => {setOpen(false)}}
            >
                <div className="main-menu">

                    <div className="main-menu-header">
                        <Link to="/app/main">
                            <Logo type="block" height="20px" color="#c0c0c0" />
                        </Link>
                    </div>


                    <div className="main-menu-body">
                        {/* <Search light={false} /> */}
                        
                        <nav className="main-menu-nav">

                            {store.AppStore.checkJwtRole("account_manager") ? (
                                <Button className="main-menu-link" component={Link} to="/app/report-management">
                                    <Icons.Report fontSize="small" />
                                    <span>Report Management</span>
                                </Button>
                            ) : null}

                            <Button className="main-menu-link" component={Link} to={`/app/main/${store.AppStore.sessionInfo.parentAccount.uid}/${store.AppStore.sessionInfo.userUid}`}>
                                <Icons.User fontSize="small" />
                                <span>User Profile</span>
                            </Button>

                            <a href="https://file.aoralaw.com/images/Aora ND User Guide WEB v1.1.pdf" target="_blank" rel="noreferrer">
                                <Button className="main-menu-link">
                                    <Icons.Info fontSize="small" />
                                    <span>Guide</span>
                                </Button>
                            </a>   

                            <a href="https://file.aoralaw.com/images/Aora ND Getting Started.pdf" target="_blank" rel="noreferrer">
                                <Button className="main-menu-link">
                                    <Icons.Expert fontSize="small" />
                                    <span>Getting Started</span>
                                </Button>
                            </a>    

                            {store.AppStore.checkJwtRole("sysuser") ? (
                                <Button className="main-menu-link" component={Link} to="/app/maint">
                                    <Icons.Maintenance fontSize="small" />
                                    <span>Server Maintenance</span>
                                </Button>
                            ) : null}

                            <Button className="main-menu-link" onClick={() => AlertModal({body: <TicketRequestForm />})} >
                                <Icons.Bug fontSize="small" />
                                <span>Report a problem</span>
                            </Button>

                            {/* <Button className="main-menu-link" component={Link} to="/app/tax">
                                <Icons.Expert fontSize="small" />
                                <span>Tax</span>
                            </Button> */}
                            
                            {process.env.NODE_ENV === "development" ? (
                                <Button className="main-menu-link" component={Link} to="/app/ui-demo">
                                    <Icons.Component fontSize="small" />
                                    <span>UI Testing</span>
                                </Button>
                            ) : null}

                            <Button className="main-menu-link" onClick={() => {store.AppStore.logout()}}>
                                <Icons.Exit fontSize="small" />
                                <span>Logout</span>
                            </Button>
                        </nav>
                    </div>
                </div>
            </Drawer>
        </React.Fragment>
    )
}