import { observer } from 'mobx-react-lite';
import React from 'react';
import { Link, useParams } from 'react-router-dom';
import { store } from '../../../stores/Store';
import ViewBanner from '../../layout/ViewBanner';
import TaxDetailPanel from './TaxDetailPanel';
import TaxNavigationPanel from './TaxNavigationPanel';
import TaxQuestionPanel from './TaxQuestionPanel';


export default function TaxMainView () {        

    var params = useParams()

    store.TaxNodeStore.handleUri(params)

    return (
        <>      
            <ViewBanner title={<TaxBreadcrumb />} />
            
            <div className="view view-main">
                <TaxNavigationPanel />
                <TaxQuestionPanel />
                <TaxDetailPanel />
            </div>
        </>
    )
}

const TaxBreadcrumb = observer(function BreadCrumb () {

    return (
        <div className="breadcrums">
            
            {store.TaxNodeStore.breadcrumb.map((nodeRef, i) => (
                <React.Fragment key={i}>
                    {i > 0 ? <div className="breadcrumb-delimiter">/</div> : null}

                    <Link to={`/app/tax/${nodeRef?.uid}`}>
                        {nodeRef.name}
                    </Link>
                </React.Fragment>
            ))}

        </div>
    )
})
