import React from "react"
import { format } from "date-fns"
import { isValid } from "date-fns/esm"
import Help from "../../../../misc/Help"
import { DisplayValue } from "../../../.."
import { observer } from "mobx-react-lite"
import { Icons } from "../../../../misc/Icons"
import { Theme } from "../../../../misc/Theme"
import { store } from "../../../../stores/Store"
import TruncText from "../../../../misc/TruncText"
import { Report } from "../../../../models/Report"
import NodeOptionsMenu from "../../../../misc/NodeMenus"
import { CircularProgress } from "@mui/material"

interface ReportProps {
    report: Report;
}

export default observer(function Report ({report}: ReportProps) {

    var enquiry = store.EnquiryStore.enquiry
    var fileStatus = DisplayValue("report_file_status", report.fileStatus)
    if (enquiry.status === "REPORT_DATA_REQUIRED") {
        fileStatus = "More data required"
    }

    const DateCell = () => {
        if (report.fileStatus === "R" && enquiry.status === "DEFINITIVE") {
            return <>Generating...</>
        }

        if (isValid(report.generatedAt)) {
            return <>{format(new Date(report.generatedAt), "dd-MM-yyyy")}</>
        }

        return <>{format(new Date(report.requestedAt), "dd-MM-yyyy")}</>
    }

    const StatusCell = () => {
        
        if (report.fileStatus === "R" && enquiry.status === "DEFINITIVE") {
            return <CircularProgress style={{width: "10px", height: "10px"}} />
        } 
        
        var color = Theme.palette.success.dark
        var label = "Valid"

        if (!report.valid) {
            color = Theme.palette.error.dark
            label = "Invalid"
        }

        if (report.fileStatus === "X") {
            color = Theme.palette.warning.dark
            label = "Errors"
        }

        if (report.fileStatus === "F") {
            color = Theme.palette.error.dark
            label = "Failed"
        }

        return <span style={{color: color}}>{label}</span>
    }

    return (
        <div className="section-row report-row" style={{display: "grid", gridTemplateColumns: "22px auto 120px 70px 20px 40px 25px"}}>
            <div className="section-cell">
                <Icons.Report style={{color: Theme.palette.primary.main}} />
            </div>
            <div className="section-cell">
                <TruncText text={report.label} />
            </div>
            
            <div className="section-cell">
                <TruncText text={DisplayValue("report_type", report.reportType)} />
            </div>
            <div className="section-cell">
                <DateCell />
            </div>
            <div className="section-cell">
                {report.outcome?.includes("Y") 
                    ? <Help content={<Icons.Positive style={{color: "green", fontSize: "12px"}} />}>This report shows a positive outcome</Help>
                    : <Help content={<Icons.Negitive style={{color: "grey", fontSize: "12px"}} />}>This report shows a negitive outcome</Help>}
            </div>
            <div className="section-cell">
                <StatusCell />
            </div>
            <div className="section-cell">
                <NodeOptionsMenu node={report} />
            </div>
        </div>
    )
})