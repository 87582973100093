import { Switch } from "@mui/material";
import { InputFieldProps } from "../../../models/Interfaces";

interface CheckboxFieldProps extends InputFieldProps {
    value: boolean;
    className?: string;
}

export default function CheckboxField ({
    name = "",
    value,
    label,
    onChange,
    warning = "",
    formik = false,
    disabled = false,
    className=""
}: CheckboxFieldProps) {

    return (
        <div className={`form-field field-checkbox ${className}`}>
            <label>
                <input
                    name={name} 
                    checked={value}
                    type="checkbox"
                    disabled={disabled}
                    onChange={(e) => {
                        e.target.value = e.target.checked.toString()
                        formik ? onChange(e) : onChange({name: name, value: e.target.checked ? "true" : "false"})
                    }} 
                />
                <span>{label}</span>
            </label>
            
            {warning ? <span className="warning">{warning}</span> : null}
        </div>
    )
}

export function SwitchField ({
    name,
    value,
    label,
    onChange,
    warning = "",
    formik = false,
    disabled = false,
    className=""
}: CheckboxFieldProps) {
    return (
        <div className={`form-field field-checkbox ${className}`}>
            <label>
                <Switch
                    name={name} 
                    checked={value}
                    type="checkbox"
                    size="small"
                    disabled={disabled}
                    value={!!value}
                    onChange={(e) => {
                        formik ? onChange(e) : onChange({name: name, value: e.target.checked ? "true" : "false"})
                    }} 
                />
                <span>{label}</span>
            </label>
            
            {warning !== false ? <span className="warning">{warning}</span> : null}
        </div>
    )
}