import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import TaxEnquiryDetail from "./TaxEnquiryDetail"
import Panel, { PanelBody, PanelHeader, PanelLabel } from "../../layout/Panel"


export const PanelWrapper = observer(({children}) => {

    const {step, index, cancel, leafNode} = store.TaxNodeStore

    return (
        <Panel index={2} className="detail" width="100%" hidden={!leafNode}>
            <PanelLabel title="Detail panel" />
            
            <PanelHeader>
                <h4>{leafNode?.label}</h4>
                {step !== "UPLOAD" ? <Button className="btn btn-xs" variant="contained" onClick={() => cancel()}>Cancel</Button> : null}
            </PanelHeader>
            
            <PanelBody>{children}</PanelBody>
        </Panel>
    )
})


export default observer(function TaxDetailPanel () {  

    var node = store.TaxNodeStore.leafNode
    var type = node?.uid.split("_")[0]

    if (!node) return <PanelWrapper></PanelWrapper>

    if (node.entity_type === "ENQUIRY") return <TaxEnquiryDetail />

    if (node.entity_type === "PRIN") return <PrincipalEntity node={node} />

    if (node.institutional_investor_type) return <InstitutionalInvestor node={node} />

    if (type === "RELATION-GROUP") return <RelationGroup node={node} />

    return (
        <PanelWrapper>
            <div className="panel-columns">
                <div>
                    <div className="detail-section">

                        <div className="header-content section-subheader">
                            <h5>Basic details</h5>
                        </div>

                        <div className="section-table">
                            {Object.keys(node).map((key, i) => {
                                
                                if (key === "entity_id") return <></>

                                return (
                                    <div key={i} className="section-row attribute-row">
                                        <div className="section-cell">{key?.replace("label", "Name").replace("entity_type", "Type")}</div>
                                        <div className="section-cell">{typeof node[key] === 'object' ? JSON.stringify(node[key]) : node[key]}</div>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </div>
                </div>

                <div></div>
            </div>
        </PanelWrapper>
    )
})


function RelationGroup ({node}) {
    return (
        <PanelWrapper>                
            <div className="panel-columns">
                <div>
                    <div className="detail-section">

                        <div className="header-content section-subheader" style={{display: "flex", justifyContent: "space-between"}}>
                            <h5>Relationships</h5>
                            <Button className="btn btn-xs" onClick={() => store.TaxNodeStore.setInformationRequest(1)}>Add new relationship</Button>
                        </div>

                        <div className="section-table">

                            <div className="section-row attribute-row">
                                <div className="section-cell" style={{width: "200px"}}><b>Relationship</b></div>
                                <div className="section-cell"><b>Entity A</b></div>
                                <div className="section-cell"><b>Entity B</b></div>
                            </div>

                            {node.childrenObjects.map((rel, i) => {
                                var to = store.TaxNodeStore.findNodeInTree(rel.to)
                                var from = store.TaxNodeStore.findNodeInTree(rel.from)
                                return (
                                    <div className="section-row attribute-row">
                                        <div className="section-cell" style={{width: "200px"}}>{store.TaxNodeStore.relationshipDisplayDict[rel.relationship_type]}</div>
                                        <div className="section-cell">{from?.label}</div>
                                        <div className="section-cell">{to?.label}</div>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </div>
                </div>

                <div>
                    <div className="detail-section">
                        <div className="section-header section-subheader">
                            <h5>Relationship diagram</h5>
                        </div>
                        <a href={`/images/graphs/${node.uid}.svg`} target="_blank">
                            <img src={`/images/graphs/${node.uid}.svg`} style={{width: "100%", height: "auto"}} />
                        </a>
                    </div>
                </div>
            </div>                
        </PanelWrapper>
    )
}


function PrincipalEntity ({node}) {
    return (
        <PanelWrapper>
            <div className="panel-columns">
                <div>
                    <div className="detail-section">

                        <div className="header-content section-subheader">
                            <h5>Basic details</h5>
                        </div>

                        <div className="section-table">
                            <div className="section-row attribute-row">
                                <div className="section-cell">Entity type</div>
                                <div className="section-cell">{node.entity_type}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">REIT type</div>
                                <div className="section-cell">{node.reit_type}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Incorporated</div>
                                <div className="section-cell">{node.incorporated}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Incorporation Registration Number</div>
                                <div className="section-cell">{node.incorporation_reg_num}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Centrally managed and controlled</div>
                                <div className="section-cell">{node.cmc}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Tax resident</div>
                                <div className="section-cell">{node.tax_residence_places}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Open ended investment company</div>
                                <div className="section-cell">{node.oeic}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Number of ordinary share classes</div>
                                <div className="section-cell">{node.num_ord_share_classes}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Fixed dividends</div>
                                <div className="section-cell">{node.ord_share_fixed}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Other rights</div>
                                <div className="section-cell">{node.ord_share_other_rights}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Admitted to trade</div>
                                <div className="section-cell">{node.shares_admit_for_trading_date}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Listed</div>
                                <div className="section-cell">{node.shares_listed_from_date}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Traded</div>
                                <div className="section-cell">{node.shares_last_traded_date}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Stock exchange</div>
                                <div className="section-cell">{node.shares_exchange}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div>
                    <div className="detail-section">
                        <div className="section-header section-subheader">
                            <h5>Accounting periods</h5>
                        </div>
                        <div className="section-table">
                            <div className="section-row attribute-row">
                                <div className="section-cell"><b>Account number</b></div>
                                <div className="section-cell"><b>Start date</b></div>
                                <div className="section-cell"><b>End date</b></div>
                            </div>
                            {node.accounting_periods.map((accounting_period, i) => {
                                return (
                                    <div className="section-row attribute-row">
                                        <div className="section-cell">{accounting_period.ac_num}</div>
                                        <div className="section-cell">{accounting_period.from}</div>
                                        <div className="section-cell">{accounting_period.to}</div>
                                    </div>
                                )
                            })}
                            
                        </div>
                    </div>
                </div>
            </div>
        </PanelWrapper>
    )
}


function InstitutionalInvestor ({node}) {
    return (
        <PanelWrapper>
            <div className="panel-columns">
                <div>
                    <div className="detail-section">

                        <div className="header-content section-subheader">
                            <h5>Basic details</h5>
                        </div>

                        <div className="section-table">
                            <div className="section-row attribute-row">
                                <div className="section-cell">Entity type</div>
                                <div className="section-cell">{node.entity_type}</div>
                            </div>

                            <div className="section-row attribute-row">
                                <div className="section-cell">Entity Type</div>
                                <div className="section-cell">{node.entity_type}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Type</div>
                                <div className="section-cell">{node.institutional_investor_type}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Explanation</div>
                                <div className="section-cell">{node.institutional_investor_type_notes}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Intended investors specified</div>
                                <div className="section-cell">{node.intended_investors_specified}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Deterrent specification</div>
                                <div className="section-cell">{node.deterrent_specification}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Deterrent terms</div>
                                <div className="section-cell">{node.deterrent_terms}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">At capacity</div>
                                <div className="section-cell">{node.fund_at_capacity}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Capacity fixed</div>
                                <div className="section-cell">{node.capacity_fixed}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Capacity exhausted</div>
                                <div className="section-cell">{node.capacity_exhausted}</div>
                            </div>
                            <div className="section-row attribute-row">
                                <div className="section-cell">Marketing sufficient</div>
                                <div className="section-cell">{node.marketing_sufficient}</div>
                            </div>

                        </div>
                    </div>
                </div>

                <div>

                    <div className="detail-section">
                        <div className="section-header section-subheader">
                            <h5>Relationship diagram</h5>
                        </div>
                        <a href={`/images/graphs/${node.uid}.svg`} target="_blank">
                            <img src={`/images/graphs/${node.uid}.svg`} style={{width: "auto", height: "auto", maxHeight: "70vh", margin: "auto", display: "flex"}} />
                        </a>
                    </div>

                </div>
            </div>
        </PanelWrapper>
    )
}