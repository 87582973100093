import Ajax from "../../../../Ajax"
import EntityCta from "./EntityCta"
import { Alert, AlertModal } from "../../../.."
import { observer } from "mobx-react-lite"
import { store, useStore } from "../../../../stores/Store"
import InlineMenu from "../../../../misc/InlineMenu"
import EntityDetailSection from "./EntityDetailSection"
import CreateEnquiryForm from "../../../form/enquiry/CreateEnquiryForm"
import Panel, { PanelLabel, PanelHeader, PanelBody } from "../../../layout/Panel"


export default observer(function EntityDetail () {

    const {NodeStore, AppStore} = useStore()

    var node = NodeStore.leafNode
    var flattenedNode = flattenPerson(node)

    return (
        <Panel index={2} className="detail" width="100%" loadingContent={NodeStore.loadingLeafNode}>

            <PanelLabel title="Person details" />

            <PanelHeader>

                <h4>{NodeStore.getDisplayName(node)}</h4>
                <EntityCta entity={node} />
                {node.role ? <small style={{color: "grey"}}>The {node.role}</small> : null}

                <div style={{marginLeft: "auto"}}>
                    <InlineMenu
                        title="Person options"
                        disabled={!!AppStore.sessionState}
                        menuItems={[
                            {label: "Edit person", action: () => {
                                store.NodeStore.isEditing = true
                            }},
                            {label: "New enquiry", action: () => AlertModal({body: <CreateEnquiryForm entityUid={node.uid} />, size: "auto"})},
                            // {label: "View raw data", action: () => AlertModal({body: JSON.stringify(node, null, 4), code: true, size: "xl"}) },
                            // {label: "View flattened data", action: () => AlertModal({body: JSON.stringify(flattenedNode, null, 4), code: true, size: "xl"}) }
                        ]} />
                </div>

            </PanelHeader>

            <PanelBody>
                <div className="panel-columns">
                    <div className="panel-column">
                        {flattenedNode.sections.filter((s, i) => (i < (flattenedNode.sections.length/2)) || s.uid.includes("ATTRS")) // Iterate every other section and always show attrs
                            .map((section: any, section_index: number) => <EntityDetailSection entityUid={node.uid} key={section_index} section={section} />)}
                    </div>
                    <div className="panel-column">
                        {flattenedNode.sections.filter((s, i) => !(i < (flattenedNode.sections.length/2)) && !s.uid.includes("ATTRS")) // Iterate every other section
                            .map((section: any, section_index: number) => <EntityDetailSection entityUid={node.uid} key={section_index} section={section} />)}
                    </div>
                </div>
            </PanelBody>

        </Panel>
    )
})


var allowableCertainties = []


const flattenPerson = (node) => { // Return flat person

    var person = {...node}

    delete person.childrenObjects
    delete person.validChildTypeStrings
    delete person.childrenLoaded
    delete person.childrenSorted

    var nonAbsenceSections = node.childrenObjects.filter(section => !section.label.includes("Absence") && !section.label.includes("Presence"))
    person.sections = nonAbsenceSections.map(section => {
        return flattenSection(section, [], [person.uid])
    }).reverse()


    // Handle absence sections defferently; flatten the top 2 nodes
    var absenceSections = node.childrenObjects.filter(section => section.label.includes("Absence"))
    absenceSections.forEach(absenceSection => {
        absenceSection.childrenObjects.forEach(absenceUserCountry => {
            var absence = flattenSection(absenceUserCountry, ["Absence from "], [person.uid])
            person.sections.push(absence)
        })
    })

    // Handle presence sections defferently; flatten the top 2 nodes
    var presenceSections = node.childrenObjects.filter(section => section.label.includes("Presence"))
    presenceSections.forEach(presenceSection => {
        presenceSection.childrenObjects.forEach(presenceUserCountry => {
            var label = "Presence in " + presenceUserCountry.label
            presenceUserCountry.label = ""
            var presence = flattenSection(presenceUserCountry, [label], [person.uid])
            person.sections.push(presence)
        })
    })

    return person
}


const flattenSection = (section, parentLabels = [], parentUids = []) => { // return flat section

    var label = section.label ? [...parentLabels, section.label] : parentLabels

    var newSection = {
        label: label,
        rows: [],
        statusHeading: [],
        subSections: [],
        uid: section.uid,
        canEdit: section.canEdit,
        canDelete: section.canDelete,
        uidPath: [...parentUids, section.uid]
    }

    handleChildren(section.childrenObjects, newSection, label, newSection.uidPath)

    return newSection
}


const handleChildren = (children, newSection, parentLabels = [], parentUids = []) => { // Mutate section in 2nd argument

    children?.forEach(child => {

        switch (child.typeName) {
            // Section node

            case "Aora.Nationality.Data.AbsenceBlockNode":
                newSection.subSections.push({...child, label: [child.label], rows: child.absenceItems, statusHeading: [], subSections: []})
                break

            case "Aora.Nationality.Data.ListNode":
            case "Aora.Nationality.Data.StructNode":
            case "Aora.Nationality.Data.DictNode":

                var subSection = flattenSection(child, parentLabels, parentUids)
                newSection.subSections.push(subSection)
                break

            // Branch nodes
            case "Aora.Nationality.Data.LStatusNode":
            case "Aora.Platform.Data.SectionNode":

                if (child.typeName === "Aora.Nationality.Data.LStatusNode" && !child.label) {
                    allowableCertainties = child.allowableCertainties
                }

                var SectionNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Platform.Data.SectionNode")
                handleChildren(SectionNodes, newSection, parentLabels, parentUids)
                var DictNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.DictNode")
                handleChildren(DictNodes, newSection, parentLabels, parentUids)
                var ListNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.ListNode")
                handleChildren(ListNodes, newSection, parentLabels, parentUids)
                var LStatusNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.LStatusNode")
                handleChildren(LStatusNodes, newSection, parentLabels, parentUids)
                var StructNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.StructNode")
                handleChildren(StructNodes, newSection, parentLabels, parentUids)
                var AttributeNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Platform.Data.AttributeNode")
                handleChildren(AttributeNodes, newSection, parentLabels, parentUids)
                var LStatusItemNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.LStatusItemNode")
                handleChildren(LStatusItemNodes, newSection, parentLabels, parentUids)
                var NameNodes = child.childrenObjects?.filter((child: any) => child.typeName === "Aora.Nationality.Data.NameNode")
                handleChildren(NameNodes, newSection, parentLabels, parentUids)
                break

            // Leaf nodes
            case "Aora.Nationality.Data.NameNode":
                newSection.rows.push({
                    ...child,
                    label: child.currency.join(", "),
                    value: child.label,
                    certainty: "CERTAIN",
                    parentUids: parentUids
                })
                break

            case "Aora.Platform.Data.AttributeNode":
                newSection.rows.push(child)
                break

            case "Aora.Nationality.Data.LStatusItemNode":
                newSection.statusHeading.push({...child, allowableCertainties: allowableCertainties})
                break

            default:
                break
        }
    })
}


