import { createTheme } from "@mui/material";

var color = `
$primary_dark:#39465d;
$primary_main:#526485;
$primary_light:#74839d;

$secondary_dark:#585c67;
$secondary_main:#6e7482;
$secondary_light:#8d9098;
$secondary_contrastText:#ffffff;

$error_main:#c76466;
$error_dark:#c24b4e;
$error_light:#e87a7d;

$success_dark:#548755;
$success_main:#6fa871;
$success_light:#8eba8f;
$success_contrastText:#ffffff; 

$info_dark:#eeeeee;
$info_main:#ffffff;
$info_light:#ffffff;

$warning_dark:#D68904;
$warning_main:#ffa90f;
$warning_light:#ffd12b;
$warning_contrastText:#ffffff;`

const parseColorPalette = () => {

	var palette = {}
	var lines = color.match(/\$.*;/g)
	
	lines.forEach(line => {
		
		line = line.replace(";", "").replace("$", "")
		var key_arr = line.split(":")[0]
		var value_arr = line.split(":")[1]
		
		if (key_arr.length && value_arr.length) {
			
			var color_key = key_arr.split("_")[0]
			var shade_key = key_arr.split("_")[1]
			var value = value_arr.trim()
			
			if (!palette[color_key]) {
				palette[color_key] = {[shade_key]: value}
			} else {
				var color_dict = {...palette[color_key]}
				color_dict[shade_key] = value
				palette[color_key] = color_dict
			}
		}
	})

	return palette
}

function GenerateTheme () {

	var palette: any = parseColorPalette()

	console.log("plalette", palette)

	return createTheme({

		palette: { ...palette },

		typography: {
			fontFamily: "-apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', sans-serif",
			// fontFamily: '"Open Sans", "Trebuchet MS", "Gill Sans", "Verdana", "Tahoma", "Arial", "sans-serif"'
		},
	
		components: {
			MuiTextField: {
				defaultProps: {
					variant: 'standard',
				}
			},
			MuiSelect: {
				defaultProps: {
					variant: 'standard',
				}
			},
			MuiFormControl: {
				defaultProps: {
					variant: 'standard',
				}
			},
			MuiButton: {
				defaultProps: {
					size: 'small',
				},			
			},
			MuiSwitch: {
				defaultProps: {
					size: 'small',
				}
			},
			MuiMenuItem: {
				styleOverrides: {
					root: {width: 'auto',}
				}
			},
		},
	});
}


export const Theme = GenerateTheme()