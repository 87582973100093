import React from "react"
import Ajax from "../../../../Ajax"
import { Alert, AlertModal, DisplayDictionary, DisplayValue } from "../../../.."
import { Button } from "@mui/material"
import Field from "../../../form/fields/Field"
import { store } from "../../../../stores/Store"
import { addDays, differenceInDays, format, formatDuration } from "date-fns"
import { observer } from "mobx-react-lite"
import TruncText from "../../../../misc/TruncText"
import { subDays } from "date-fns/esm"
import { Icons } from "../../../../misc/Icons"
import Help from "../../../../misc/Help"


interface EntityDetailSectionProps {
    entityUid: string; 
    section: any;
    isSubSection?: boolean;
    isEditing?: boolean;
}


export default observer(function EntityDetailSection ({entityUid, section, isSubSection = false}: EntityDetailSectionProps) {

    const [showCertainty] = React.useState(true)
    const [collapsed, setCollapsed] = React.useState(section.label.length === 1 && section.label[0] === "Nationalities")

    var isEditingSection = section.uidPath?.includes(store.NodeStore.highlightSectionUid)
    var isEditing = store.NodeStore.isEditing
    

    if (section.uid.includes("ABSENCE-BLOCK")) {
        return <AbsenceBlock node={section} isEditing={isEditing} />
    }

    // If section contains only subsections don't render anything
    // for this section and go straight to subsections.
    if (!section.rows.length && !section.statusHeading.length && isSubSection) {
        return section.subSections.map((subSection, i) => <EntityDetailSection entityUid={entityUid} key={i} section={subSection} isSubSection={true} />)
    }

    var declareNoEntries = !section.rows.length && !section.subSections.length
    var sortedRows = section.rows?.sort((a, b) => a.label?.localeCompare(b.label))
    var label = section.label?.length ? section.label.slice(1).join(" / ") : ""
    label = isSubSection ? label : section.label

    var nameRows = sortedRows.filter(row => row.typeName === "Aora.Nationality.Data.NameNode")
    var remainingrows = sortedRows.filter(row => row.typeName === "Aora.Platform.Data.AttributeNode")

    return (
        <div className={`detail-section ${isEditingSection ? "editing" : ""}`}>
            <div className="section-table">
                {isEditing && section.canDelete ? <DeleteButton uid={section.uid} /> : null}

                {label ? (
                    <div className={`section-header ${isSubSection ? " section-subheader" : ""}`}>
                        <div className="header-content">
                            <h5 onClick={() => setCollapsed(!collapsed)}>{label}</h5>
                            {/* {section.statusHeading.length ? <Button color="primary" onClick={() => setShowCertainty(!showCertainty)}>Information Status</Button> : null} */}
                        </div>
                        {!collapsed && showCertainty ? section.statusHeading.map((row, i) => <InfoStatusRow key={i} node={row} isEditing={isEditing} />) : null}
                    </div>
                ) : null}

                {!collapsed ? (
                    <>
                        {nameRows.map((row, i) => <NameRow key={i} node={row} isEditing={isEditing} />)}
                        {remainingrows.map((row, i) => <AttributeRow key={i} node={row} isEditing={isEditing} />)}

                        {declareNoEntries ? (
                            <div className="section-row attribute-row">
                                <div className="section-cell">No entries</div>
                            </div>
                        ) : null}
                    </>
                ) : null}
                

            </div>
            {!collapsed 
                ? section.subSections.map((subSection, i) => <EntityDetailSection key={i} entityUid={entityUid} section={subSection} isSubSection={true} />)
                : <Button className="btn btn-xs" onClick={() => setCollapsed(false)}>Show all</Button>}
        </div>
    )
})

const DeleteButton = ({uid}) => {

    const deleteAttr = () => {
        store.NodeStore.updateEntity(async () => {
            await Ajax.Entity.DeleteAttribute(uid)
        })
    }

    const deleteDialogue = () => {
        AlertModal({body: <h4>Are you sure you want to delete this data?</h4>, actions: [
            {label: "Cancel", variant: "text"},
            {label: "Yes", action: async () => deleteAttr()}
        ]})
    }

    return (
        <div className="section-row attribute-row">
            <div className="section-cell">
                <Button onClick={() => deleteDialogue()}>
                    Delete section<Icons.X style={{fontSize: "14px"}} />
                </Button>
            </div>
        </div>
    )
}

const AttributeRow = ({node, isEditing}) => {

    const editNode = () => {
        store.NodeStore.editEntity(async () => 
            await Ajax.Entity.Edit(node.uid)
        )
    }

    if (node.attrType === "SEX") {
        return <SexRow node={node} isEditing={isEditing} />
    }

    return (
        <div className="section-row attribute-row" data-uid={node.uid}>
            <div className="section-cell">{node.label}</div>
            
            {node.isReference && node.referenceValue 
                ? <div className="section-cell link" onClick={() => store.NodeStore.navigateNode(node.referenceValue.uid)}>{node.value}</div>
                : <div className="section-cell">{node.value?.toString()}</div>}
            
            {isEditing ? (
                <div className="section-cell" style={{width: "80px"}}>
                    <Button disabled={!node.canEdit} color="primary" onClick={() => editNode()}>Edit</Button>
                </div>
            ) : null}
        </div>
    )
}

const SexRow = ({node, isEditing}) => {

    const [value, setValue] = React.useState(node.value)

    const updateNode = () => {
        node.value = value
        store.NodeStore.updateEntity(async () => {
            await Ajax.Entity.Update("sex", node)
        })
    }

    var disableSave = (value === node.value)

    return (
        <div className="section-row attribute-row" data-uid={node.uid}>
            <div className="section-cell">{node.label}</div>
            {isEditing
                ? (
                    <>
                        <div className="section-cell">
                            {node.canUpdate ? (
                                <Field.Select disabled={!node.canUpdate} formik value={value} options={DisplayDictionary("sex", true)} onChange={(e) => setValue(e.target.value)} warning={false} />
                            ) : (
                                <Help content={DisplayValue("sex", value)}>
                                    {!node.canUpdate ? <>This persons sex cannot be updated because they have relationships defined with other people</> : <></>}
                                </Help>
                            )}
                        </div>
                        <div className="section-cell" style={{width: "80px"}}>
                            <Button onClick={updateNode} disabled={disableSave}>Save</Button>
                        </div>
                    </>
                ) : <div className="section-cell">{DisplayValue("sex", value)}</div>}
        </div>
    )
}


const InfoStatusRow = ({node, isEditing}) => {

    const [value] = React.useState(node.certainty)

    const updateNode = (e) => {
        node.certainty = e.target.value
        store.NodeStore.updateEntity(async () => {
            await Ajax.Entity.Update("certainty", node)
        })
    }

    var options = node.allowableCertainties.map((certainty) => {
        return {value: certainty, label: DisplayValue("information_status", certainty)}
    })
    
    if (!node.allowableCertainties.includes(node.certainty)) {
        options.push({value: node.certainty, label: DisplayValue("information_status", node.certainty), disabled: true})
    }   

    return (
        <div className="section-certainty" data-uid={node.uid}>
            <div>{node.period}</div>
            <div>
                <Field.Select
                    formik
                    disabled={!isEditing}
                    disableUnderline
                    warning={false}
                    value={value}
                    onChange={updateNode}
                    options={options}
                />
            </div>
        </div>
    )
}

const AbsenceBlock = ({node, isEditing}) => {

    const editNode = () => {
        store.NodeStore.editEntity(async () => 
            await Ajax.Entity.Edit(node.uid)
        )
    }

    var shiftDays = true

    return (
        <div className={`detail-section`}>
            <div className="section-table">
                <div className={`section-header section-subheader`}>
                    <div className="header-content">
                        <h5>{node.label}</h5>
                        {isEditing ? <Button disabled={!node.canEdit} onClick={() => editNode()}>Edit</Button> : null}
                    </div>
                </div>
                {node.rows?.length ? (
                    <div className="section-row absence-row">
                        <div className="section-cell"><b>{shiftDays ? "Departed" : "From"}</b></div>
                        <div className="section-cell"><b>{shiftDays ? "Returned" : "To"}</b></div>
                        <div className="section-cell"><b>Absent</b></div>
                        <div className="section-cell"><b>Purpose</b></div>
                        <div className="section-cell"><b>Countries</b></div>
                    </div>
                ) : (
                    <div className="section-row attribute-row">
                        <div className="section-cell">No absences</div>
                    </div>
                )}
                {node.rows?.map((row, i) => {

                    // Change dates from first/last-full-day to date-departed/returned
                    var fromDate = shiftDays ? subDays(new Date(row.fromDate), 1) : new Date(row.fromDate)
                    var toDate = shiftDays ? addDays(new Date(row.toDate), 1) : new Date(row.toDate)
                
                    return (
                        <div key={i} className="section-row absence-row">
                            <div className="section-cell">{format(fromDate, "dd/MM/yyyy")}</div>
                            <div className="section-cell">{format(toDate, "dd/MM/yyyy")}</div>
                            <div className="section-cell">{formatDuration({days: differenceInDays(new Date(row.toDate), new Date(row.fromDate)) + 1}, {format: ["days"]})}</div>
                            <div className="section-cell"><TruncText text={row.purpose} /></div>
                            <div className="section-cell">
                                <TruncText text={row.countriesVisited.map((countryCode, i) => (`${i>0 ? ", " : ""}${DisplayDictionary("countries")[countryCode]}`))} />
                            </div>
                        </div>
                    )
                })}
            </div>
        </div>
    )
}

const NameRow = ({node, isEditing}) => {

    const [personalNames, setPersonalNames] = React.useState(node.personalNames)
    const [lastName, setLastName] = React.useState(node.lastName)

    const updateNode = (e) => {

        if (!personalNames || !lastName) {
            return Alert({message: "First and last names are required", color: "warning"})
        }

        node.personalNames = personalNames
        node.lastName = lastName

        store.NodeStore.updateEntity(async () => {
            await Ajax.Entity.Update("names", node)
        })
    }

    var disableSave = !node.canUpdate || (node.personalNames === personalNames && node.lastName === lastName)

    return (
        <div className="section-row attribute-row">
            <div className="section-cell">{node.label}</div>
            {isEditing ? (
                <>
                    <div className="section-cell">
                        <Field.Text label="First names" required formik warning={false} value={personalNames} onChange={(e) => setPersonalNames(e.target.value)} />
                    </div>
                    <div className="section-cell">
                        <Field.Text label="Family name" required formik warning={false} value={lastName} onChange={(e) => setLastName(e.target.value)} />
                    </div>
                    <div className="section-cell" style={{width: "130px"}}>
                        <Button onClick={updateNode} disabled={disableSave}>Save</Button>
                    </div>
                </>
            ) : <div className="section-cell">{node.value.toString()}</div>}
        </div>
    )
}