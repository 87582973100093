import Ajax from '../../../Ajax';
import Help from '../../../misc/Help';
import { Button } from '@mui/material';
import { observer } from 'mobx-react-lite'
import { Icons } from '../../../misc/Icons';
import { store } from '../../../stores/Store';
import PersonCreateForm from '../../form/entity/PersonCreateForm';
import InformationRequestForm from '../../form/InfomationRequestForm';
import Panel, { PanelBody, PanelHeader, PanelLabel } from '../../layout/Panel';
import { AlertModal } from '../../..';
import {SystemInformation} from '../../modals/SystemInformation';


export default observer(function QuestionPanel () {

    const {AppStore, QuestionStore, NodeStore} = store

    var stateLowerCase = AppStore.sessionState?.toLowerCase()
    var title = stateLowerCase
        ? `${AppStore.stateAction} ${AppStore.stateSubject}`.replaceAll("_", " ").toLowerCase().replace("node", "")
        : QuestionStore.initialisationForm?.toLowerCase().replace("_", " ").replace("node", "")
    
    var hidden = true
    if (
        stateLowerCase?.includes("running") || 
        stateLowerCase?.includes("creating person") || 
        (stateLowerCase?.includes("editing") && QuestionStore.informationRequest) || 
        QuestionStore.initialisationForm
    ) {
        hidden = false
    } 

    const cancel = () => {
        if (!QuestionStore.informationRequest && QuestionStore.initialisationForm) {
            QuestionStore.initForm(null)
        } else if (AppStore.sessionState?.includes("Editing")) {
            NodeStore.cancelEdit()
        } else {
            Ajax.Message.Cancel().then(() => {
                NodeStore.refreshFolderNode()
            })
        }
    }

    return (
        <Panel
            index={1}
            minimisable
            className="panel-question"
            hidden={hidden}
            loadingContent={hidden}
            width={hidden ? "0px" : "270px"} >

            <PanelLabel title="Information request" />

            <PanelHeader>
                <h4 style={{textTransform: "capitalize"}}>{title}</h4>

                <span style={{display: "flex", alignItems: "baseline"}}>
                    {stateLowerCase?.includes("running") && stateLowerCase?.includes("_ff") ? (
                        <Help content={<Icons.FastForward style={{display: "flex", fontSize: "16px", color: "lightgrey"}} />}>
                            <div>
                                <h3>Enquiry in fast-forward mode</h3>
                                <p>This means that the system will make assumptions to speed up the enquiry. Once an outcome is reached, AORA will ask you questions to confirm these assumptions.</p>
                            </div>
                        </Help>
                    ) : null}

                    <Button
                        className="btn btn-sm"
                        aria-label="Pause enquiry"
                        onClick={() => cancel()}>
                        {stateLowerCase?.includes("running") ? <Icons.Pause /> : <Icons.X />}
                    </Button>
                </span>
            </PanelHeader>

            <PanelBody>

                {!stateLowerCase && QuestionStore.initialisationForm === "CREATE_PERSON" ? 
                    <PersonCreateForm caseUid={NodeStore.folderNode.uid} /> : null}

                {stateLowerCase ? <InformationRequestForm /> : null}
                
                <br/>

                {store.ConnectionStore.connectionState !== "Connected" 
                    ? <small className="link" onClick={() => AlertModal({body: <SystemInformation />, size: "lg"})}>You have a faulty connection and may not receive questions</small>
                    : null}
            </PanelBody>

        </Panel>
    )
})