import Ajax from '../Ajax'
import { Alert } from '..'
import { store } from './Store'
import { makeAutoObservable } from 'mobx'


export class AutomationStore {

    loading: boolean = false
    implementedForms: string[] = ["UMK"]

    constructor () {
        makeAutoObservable(this)
    }

    get enquiryAutomations () {
        
        var automations = []

        if (store.EnquiryStore.enquiry) {
            var rSection = store.EnquiryStore.enquiry?.childrenObjects?.find(section => section.name === "AUTOMATION_RESULTS")
            if (rSection) {
                automations = rSection.childrenObjects
            }
        }

        // Order by runDt
        automations = [...automations].sort((a, b) => {
            console.log({...a})
            return new Date(b.runDt).getTime() - new Date(a.runDt).getTime()
            // return b.uid.localeCompare(a.uid)
        })

        // Then remove any old versions for each automationId
        var existingIds = []
        var trimmedList = []

        automations.forEach(a => {
            if (!existingIds.includes(a.automationId)) {
                trimmedList.push(a)
                existingIds.push(a.automationId)
            }
        })

        return trimmedList
    }

    run = async (automationId) => {
        
        this.loading = true
        
        await Ajax.Automation.Run(store.EnquiryStore.enquiry.uid, automationId).then(() => {
            store.AlertStore.Close()
            store.NodeStore.refreshLeafNode()
        }).catch((response) => {
            Alert({message: response.data?.detail})
        })

        this.loading = false
    }


    automationOptions = [
        {
            automated: true,
            name: "Registration application UKM",
            image: "/images/web-auto-ukm.png",
            link: "https://visas-immigration.service.gov.uk/product/nationality-ukm",
            id: "UKM"
        },
        {
            automated: true,
            name: "Naturalisation application AN",
            image: "/images/web-auto-na.png",
            link: "https://visas-immigration.service.gov.uk/product/nationality-an",
            id: "AN"
        },
        {
            name: "Registration application for child MN1",
            image: "/images/web-auto-mn1.png",
            link: "https://visas-immigration.service.gov.uk/product/nationality-mn1",
            id: "MN1"
        },


        
        {
            name: "Form UKF",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/form-ukf-guidance",
            id: "UKF",
        }, 
        {
            name: "Register as a British citizen - stateless person born before 1983",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/register-as-a-british-citizen-stateless-person-born-before-1983-form-s1",
            id: "S1",
        }, 
        {
            name: "Form RS1",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/form-rs1-guidance",
            id: "RS1",
        }, 
        {
            name: "",
            image: "/images/AORA-A.png",
            link: "",
            id: "EM",
        }, 
        {
            name: "Form B(OS)",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/form-bos-guidance",
            id: "B(OS)",
        }, 
        {
            name: "Application for registration of a child under 18 as a British citizen",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/form-mn1-guidance",
            id: "MN1",
        }, 
        {
            name: "Form T",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/form-t-guidance",
            id: "T",
        }, 
        {
            name: "Form B(OTA)",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/register-as-a-british-citizen-form-bota",
            id: "B(OTA)",
        }, 
        {
            name: "Form ARD",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/application-for-registration-as-a-british-citizen-form-ard",
            id: "ARD",
        }, 
        {
            name: "Form AN",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/form-an-guidance",
            id: "AN",
        }, 
        {
            name: "UK-born stateless person - Form S3",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/register-as-a-british-citizen-uk-born-stateless-person-form-s3",
            id: "S3",
        }, 
        {
            name: "Stateless person - Form S2",
            image: "/images/AORA-A.png",
            link: "https://www.gov.uk/government/publications/register-as-a-british-citizen-stateless-person-born-outside-the-uk-or-british-overseas-territories-form-s2",
            id: "S2",
        }, 
    ]

}