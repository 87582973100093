import {
    Settings, 
    Support, 
    Menu, 
    ExitToApp, 
    School, 
    Layers, 
    Clear, 
    Home, 
    Accessibility, 
    People, 
    ArrowRight, 
    ArrowLeft, 
    FolderOpen, 
    BlurOn, 
    Balance, 
    ChevronRight, 
    ChevronLeft, 
    Done, 
    Subject, 
    DoubleArrow, 
    InfoOutlined, 
    Search, 
    Download,
    MiscellaneousServices,
    OpenInNew,
    MoreHoriz,
    WorkOutline,
    UploadFile,
    AccountCircle,
    Apartment,
    Token,
    Remove,
    Add,
    SettingsInputComponent,
    Sensors,
    ArrowUpward,
    ArrowDownward,
    ArrowCircleRight,
    ArrowCircleLeft,
    PlayArrow,
    Pause,
    Refresh,
    QuestionMark,
    BugReport,
    Edit,
    Save,
    FastForward,
    Woman,
    Man,
    TravelExplore,
    TaskAlt,
    HighlightOff,
    FiberManualRecord,
    Warning,
    Handyman,
    Business
} from '@mui/icons-material';


export const Icons = {

    Account: Apartment,
    SubAccount: Business,
    User: AccountCircle,
    Automation: MiscellaneousServices,
    Folder: FolderOpen,
    Case: WorkOutline,
    Enquiry: Balance,
    Report: Subject,
    Person: Accessibility,
    Woman: Woman,
    Man: Man,
    People: People,
    Expert: School,

    Component: SettingsInputComponent,
    Connection: Sensors,
    Download: Download,
    Settings: Settings,
    Support: Support,
    Exit: ExitToApp,
    Layers: Layers,
    Token: Token,
    Menu: Menu,
    Home: Home,
    Positive: TaskAlt,
    Negitive: HighlightOff,

    TerritoryFinder: TravelExplore,
    QuestionMark: QuestionMark,
    UploadFile: UploadFile,
    Info: InfoOutlined,
    More: MoreHoriz,
    Open: OpenInNew,
    Search: Search,
    Minus: Remove,
    Plus: Add,
    Blur: BlurOn,
    Run: PlayArrow,
    Refresh: Refresh,
    Warning: Warning,
    Bug: BugReport,
    Pause: Pause,
    Edit: Edit,
    Save: Save,
    Tick: Done,
    X: Clear,
    XCircle: Clear,
    Dot: FiberManualRecord,
    Maintenance: Handyman,
    
    FastForward: FastForward,
    ChevronRight: ChevronRight,
    ChevronLeft: ChevronLeft,
    ArrowDown: ArrowDownward,
    ArrowUp: ArrowUpward,
    DoubleArrow: DoubleArrow,
    ArrowRight: ArrowRight,
    ArrowLeft: ArrowLeft,
    ArrowCircleRight: ArrowCircleRight,
    ArrowCircleLeft: ArrowCircleLeft,
    
}

export function TypeIcon ({node}) {
    switch (node.typeName) {
        case "Aora.Platform.Data.AccountNode":
            return node.parentAccount?.uid === "ACCOUNT_-1" ? <Icons.SubAccount className="type-icon" /> : <Icons.Account className="type-icon" />
        case "Aora.Platform.Data.UserNode":
            return <Icons.User className="type-icon" />
        case "Aora.Platform.Data.FolderNode":
            return <Icons.Folder className="type-icon" />
        case "Aora.Platform.Data.CaseNode":
            return <Icons.Case className="type-icon" />
        case "Aora.Nationality.Data.PersonNode":
            if (node.sex) {
                return node.sex === "F" ? <Icons.Woman className="type-icon" /> : <Icons.Man className="type-icon" />
            }
            return <Icons.Person className="type-icon" />
        case "Aora.Platform.Data.EnquiryNode":
            return <Icons.Enquiry className="type-icon" />
        case undefined:
        case null:
        default:
            return <Icons.Blur className="type-icon" />
    }
}
