import FolderPanel from './FolderPanel';
import DetailPanel from "./detail/DetailPanel";
import QuestionPanel from "./QuestionPanel";
import { useParams } from 'react-router-dom';
import { store } from '../../../stores/Store';
import ViewBanner from '../../layout/ViewBanner';
import BreadCrumb from './BreadCrumb';
import ErrorBoundary from '../../../misc/ErrorBoundary';
import React from 'react';


export default function MainView () {        

    var params = useParams()

    React.useEffect(() => {
        store.NodeStore.handleUri(params)
    })

    return (
        <ErrorBoundary>
            <ViewBanner title={<BreadCrumb />} />
            
            <div className="view view-main">
                <FolderPanel />            
                <QuestionPanel />
                <DetailPanel />
            </div>
        </ErrorBoundary>
    )
}
