import { observer } from 'mobx-react-lite'
import { store } from '../../../stores/Store'
import Panel, { PanelBody, PanelHeader, PanelLabel } from '../../layout/Panel'
import TaxNavigationListItem from './TaxNavigationListItem'


export default observer(function TaxNavigationPanel () {

    var {TaxNodeStore} = store
    var {folderNode} = TaxNodeStore

    var panelWidth = "270px"
    var classes = TaxNodeStore.loading ? "panel-folder disabled" : "panel-folder"

    var groupedList = {}

    folderNode.childrenObjects.forEach((node) => {
        var type = node.uid.split("_")[0]
        if (type in groupedList) {
            groupedList[type].push(node)
        } else {
            groupedList[type] = [node]
        }
    })

    return (
        <Panel index={0} className={classes} width={panelWidth} minimisable>

           <PanelLabel title="Folder panel" />
           
           <PanelHeader>
                <h4>{folderNode?.label}</h4>
            </PanelHeader>

            <PanelBody>
                {folderNode.uid.includes("CASE") ? (
                    <nav id="folder-panel-list">
                    
                        <small style={{padding: "5px 0", fontWeight: 700}}>Enquiry</small>
                        {groupedList["ENQUIRY"]?.map((node, i) => <TaxNavigationListItem node={node} /> )}
                        {groupedList["RELATION-GROUP"]?.map((node, i) => <TaxNavigationListItem node={node} /> )}

                        <small style={{padding: "5px 0", fontWeight: 700}}>Companies</small>
                        {groupedList["COMPANY"]?.map((node, i) => <TaxNavigationListItem node={node} /> )}

                        <small style={{padding: "5px 0", fontWeight: 700}}>People</small>
                        {groupedList["PERSON"]?.map((node, i) => <TaxNavigationListItem node={node} /> )}
                    </nav>
                ) : (
                    <nav id="folder-panel-list">
                        {Object.keys(groupedList).length ? Object.keys(groupedList).map(groupKey => (
                            <>
                                {Object.keys(groupedList).length > 1 ? <small style={{padding: "5px 0", fontWeight: 700}}>{groupKey}</small> : null}
                                
                                {groupedList[groupKey].map((node, i) => (
                                    <TaxNavigationListItem node={node} />
                                ))}
                            </>
                        )) : <small>No items</small>}
                    </nav>
                )}
            </PanelBody>

        </Panel>
    )
})

