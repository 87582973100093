import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import ReportMgmtTable from './ReportMgmtTable'
import ReportMgmtPanel from './ReportMgmtPanel';
import { store, useStore } from '../../../stores/Store';
import ViewBanner from '../../layout/ViewBanner';
import Panel, { PanelBody, PanelLabel } from '../../layout/Panel';
import ErrorBoundary from '../../../misc/ErrorBoundary';
import { NodeStore } from '../../../stores/NodeStore';


export default observer(function ReportMgmtView () {

    store.AppStore.setPageTitle("Aora - Report Management")

    const { ReportStore, NodeStore } = useStore()

    useEffect(() => {
        NodeStore.reset()
        ReportStore.setReports([])
        ReportStore.getReports()
    }, [ReportStore])

    return (
        <ErrorBoundary>
            <ViewBanner title="Report Management" />

            <div className="view view-main view-report-management">

                <ReportMgmtPanel />

                <Panel index={1} width="100%" className="report-management-table-panel">
                    <PanelLabel title="Reports table" />
                    <PanelBody>
                        <ReportMgmtTable />
                    </PanelBody>
                </Panel>

            </div>
        </ErrorBoundary>
    )
})