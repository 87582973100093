import ErrorDetail from "./ErrorDetail"
import EntityDetail from "./EntityDetail"
import { observer } from "mobx-react-lite"
import EnquiryDetail from "./EnquiryDetail"
import { useStore } from "../../../../stores/Store"
import Panel, { PanelBody, PanelHeader, PanelLabel } from "../../../layout/Panel"
import UserDetail from "./UserDetail"


export default observer(function DetailPanel () {  

    const {NodeStore} = useStore()

    var node = NodeStore.leafNode

    if (NodeStore.loadingLeafNode) {
        return (
            <Panel index={2} className="detail" width="100%" loadingContent={NodeStore.loadingLeafNode}>
                <PanelLabel title="Loading" />
                <PanelHeader></PanelHeader>
                <PanelBody></PanelBody>
            </Panel>
        )
    }

    if (node === null) {
        return <></>
    }

    switch (node.typeName)
    {
        case "Aora.Nationality.Data.PersonNode":
            return <EntityDetail />
        case "Aora.Platform.Data.EnquiryNode":
            return <EnquiryDetail />
        case "Aora.Platform.Data.UserNode":
            return <UserDetail />
        case "Error":
        default:
            return <ErrorDetail />
    }       
})