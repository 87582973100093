// @ts-nocheck
import Ajax from '../Ajax';
import { store } from './Store';
import { Alert, AlertModal } from '..';
import { makeAutoObservable, reaction } from 'mobx'
import { StateChange } from '../models/StateChange';
import { InformationRequest } from '../models/InformationRequest';
import EnquiryResultModal from '../components/modals/EnquiryOutcomeModal'
import signalR, { HubConnection, HubConnectionBuilder, LogLevel } from "@microsoft/signalr";


export class ConnectionStore {

    hubConnection: HubConnection | null = null;
    stateSubjectId: string | null;
    connectionState: string | null = null;
    waiting: string[] = [];
    testCount: number = 10;
    testsVerified: number = 0;
    testComplete: boolean = true;
    transportTypes = {
        "0": "Automatic",
        1: "WebSockets (recommened)",
        2: "Server Sent Events",
        4: "Long Polling (not recommended)"
    }
    transportType: "0" | 1 | 2 | 4 = "0"

    constructor() {
        makeAutoObservable(this)
        
        reaction(() => this.hubConnection?.state, state => {
            this.connectionState = state
        })
    }

    setTransportType = async (transportTypeNumber: number) => {
        await this.stopHubConnection()
        this.transportType = transportTypeNumber
        await this.createHubConnection()
    }

    routeMessages = () => {
        this.hubConnection.on("EnquiryStatusNotification", (message: any) =>            handleEnquiryStatusNotification(message))
        this.hubConnection.on("RoleChangeNotification", (message: any) =>               this.handleRoleChange(message))
        this.hubConnection.on("InformationRequest", (message: InformationRequest) =>    store.QuestionStore.receiveInformationRequest(message))
        this.hubConnection.on("CloseConnection", (message: string = "") =>              this.stopHubConnection())
        this.hubConnection.on("StateChange", (stateChange: StateChange) =>              store.AppStore.setSessionState(stateChange))
        this.hubConnection.on("PopupRequest", (popupRequest: any) =>                    handlePopupRequest(popupRequest))
        this.hubConnection.on("RunInfo", (message: string) =>                           store.QuestionStore.handleRunInfo(message))
        this.hubConnection.on("Notify", (message: string) =>                            Alert({ message: message }))
        this.hubConnection.on("Test", (message: string) =>                              {this.testsVerified += 1})
        this.hubConnection.on("DisconnectNotification", (message: string) =>            store.AppStore.resetApp())
    }

    testConnection = () => {

        if (!this.testComplete) return

        this.testsVerified = 0
        this.testComplete = false

        Ajax.Message.Test(this.testCount).then(() => {
            this.testComplete = true
        })
    }

    handleRoleChange = (message) => {
        Alert({message: "Role change!"})
        AlertModal({body: JSON.stringify(message, null, "\t"), code: true, size: "lg"})
    }

    createHubConnection = async (first_try = true) => {

        var options = { accessTokenFactory: () => store.AppStore.token! }
        
        if (this.transportType !== "0") { options["transport"] = this.transportType }

        this.hubConnection = new HubConnectionBuilder()
            .withUrl(`${process.env.REACT_APP_SIGNALR_URL}`, options)
            .withAutomaticReconnect()
            .configureLogging(LogLevel.Information)
            .build();

        this.routeMessages()

        return await this.hubConnection.start().then(() => {
            this.connectionState = "Connected"
            return Promise.resolve()
        }).catch(async (error: signalR.HttpError) => {
            
            // Handle non-auth errors with a basic message
            if (error?.statusCode !== 401) {
                console.log("error", error)
                Ajax.Session.Log(`Frontend error when initialising signalR connection:\n${error?.message}\n${error?.stack}`)
                AlertModal({ title: "You have a faulty connection", body: <p>{error?.message}<br/>{error?.stack}</p> })
                return Promise.reject()
            }

            // If authorisation fails twice, log out
            if (first_try === false) {
                Alert({message: "ConnectionStore: Failed to connect"})
                store.AppStore.logout()
                return Promise.reject()
            }

            // Attempt to refresh auth token
            await Ajax.Session.RefreshToken().then(() => {

                Alert({message: "ConnectionStore: New token saved"})
                return this.createHubConnection(false) // When a new token is acquired, try again

            }).catch(() => {

                Alert({message: "ConnectionStore: Failed to refresh token"})
                store.AppStore.logout()
                return Promise.reject()

            })
        })
    }

    stopHubConnection = async () =>
        await this.hubConnection?.stop()
            .then(() => {
                this.hubConnection = null
            })
            .catch((error) => console.log("Error stopping connection", error))
}


const handleEnquiryStatusNotification = async (message) => {

    console.log("EnquiryStatusNotification", message)

    if (message.status === "INTERIM" || message.status === "DEFINITIVE") {
        AlertModal({body: <EnquiryResultModal enquiryUid={message.enquiryUid} />, size: "lg"})
    }
}

const handlePopupRequest = (pr: any) => {

    console.log("Popup request", pr)

    AlertModal({
        id: pr.messageId,
        title: pr.title,
        body: pr.mainText,
        requireAction: true,
        actions: pr.validResponses?.map((option) => {
            return {label: option, action: () => Ajax.Message.RespondPopup(option)}
        })
    })
}