import React from 'react';
import { Line } from "react-chartjs-2";
import { observer } from 'mobx-react-lite'
import { store } from '../../../stores/Store';


export default observer(function OneYear() {
    
    const { loading, yearMemory, yearTime, yearPostgres, yearCpu, yearSession, getPollingYearly } = store.MaintStore

    React.useEffect(() => {
        getPollingYearly()
    }, [])

    const labels2 = yearTime;

    const data2 = {
        labels: labels2,
        datasets: [
            {
                label: "memory usage",
                backgroundColor: "rgb(100, 99, 132)",
                borderColor: "rgb(100, 99, 132)",
                fill: false,
                data: yearMemory,
            },
            {
                label: "cpu usage",
                backgroundColor: "rgb(10, 99, 132)",
                borderColor: "rgb(10, 99, 132)",
                // data: [connections[0]],
                fill: false,
                data: yearCpu,
            },
        ],
    };


    const data_session2 = {
        labels: labels2,
        datasets: [
            {
                label: "open sessions",
                backgroundColor: "rgb(60, 70, 200)",
                borderColor: "rgb(60, 70, 200)",
                fill: false,
                data: yearSession
            },
            {
                label: "postgres connections",
                backgroundColor: "rgb(100, 30, 100)",
                borderColor: "rgb(100, 30, 100)",
                fill: false,
                data: yearPostgres
            }
        ]
    };

    const options = {
        scales: {
            y: {
                min: 0.00,
                max: 100.00,
                title: {
                    display: true,
                    text: 'Percentage Used',
                    font: {
                        size: 15,
                        family: 'sans-serif',
                    },
                },

            },
            x: {
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: 15,
                        family: 'sans-serif'
                    }
                }
            }

        },
        plugins: {
            sort:
            {
                enable: true
            }
        }
    };

    const options_session = {
        scales: {
            y: {
                min: 0.00,
                title: {
                    display: true,
                    text: 'Open Sessions',
                    font: {
                        size: 15,
                        family: 'sans-serif',
                    },
                },

            },
            x: {
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: 15,
                        family: 'sans-serif'
                    }
                }
            }

        }
    }

    if (loading) return <p>Loading...</p>

    return (
        <div>
            <Line data={data2} options={options} /> 
            <Line data={data_session2} options={options_session} />
        </div>
    )
})