import React from "react"
import { reaction } from "mobx"
import { Button } from "@mui/material"
import { Icons } from "../../misc/Icons"
import { store } from "../../stores/Store"
import { useEffect, useRef, useState } from "react"
import ErrorBoundary from "../../misc/ErrorBoundary"
import useResizeObserver from "use-resize-observer";


interface PanelProps {
    
    index: number;
    width?: string;
    children?: any | null;
    className?: string;
    hidden?: boolean;
    loadingContent?: boolean;
    minimisable?: boolean;
    minimisedDefault?: boolean;
}


export default function Panel ({
    
    index,
    children,     
    width,
    className = "",
    hidden = false,
    loadingContent = false,
    minimisable = true, 
    minimisedDefault = window.innerWidth < 700 && index === store.AppStore.currentPanel ? true : false

}: PanelProps) {

    // var ref = useRef(null)  
    const [minimised, setMinimised] = useState(minimisedDefault) 
    const resizer = useResizeObserver<HTMLDivElement>();
    
    const mobileAutoMinimise = React.useCallback(() => {
        if (index !== null && window.innerWidth < 700) {
            setMinimised(index !== store.AppStore.currentPanel)
        }
    }, [index])
    
    useEffect(() => {
        mobileAutoMinimise()
    }, [setMinimised, width, mobileAutoMinimise])

    reaction(() => store.AppStore.currentPanel, currentPanel => {
        mobileAutoMinimise()
    })

    const generateClasses = () => {
        // Generate style classes
        var classes = "panel ".concat(className)
        classes = hidden ? classes.concat(" panel-hidden") : classes
        classes = minimised ? classes.concat(" panel-minimised") : classes
        classes = classes.concat(` panel-width-${Math.round(resizer?.width / 100)}`)
        return classes
    }

    return (
        <div 
            ref={resizer.ref}
            id={"panel-"+index} 
            className={generateClasses()} 
            style={{
                zIndex: (-index+10)*100,
                maxWidth: window.innerWidth > 700 ? width : "none"
            }}>
            
            <ErrorBoundary>
                {/* Render children and pass additional props about minimisation */}
                {React.Children.map(children, (child) => {
                    return React.cloneElement(child, {
                        hidden: hidden,
                        minimised: minimised,
                        minimisable: minimisable,
                        loadingContent: loadingContent,
                        setMinimised: (value: boolean) => {
                            store.AppStore.currentPanel = index
                            setMinimised(value)
                        },
                        ...child.props
                    })
                })}
            </ErrorBoundary>

        </div>
    )
}

export function PanelLabel (props: any) {

    const generateClasses = () => {
        var classes = "panel-label"
        classes = props.className ? classes.concat(" "+props.className) : classes
        classes = props.hidden ? classes.concat(" panel-child-hidden") : classes
        return classes
    }

    return (
        <div className={generateClasses()} onClick={() => {props.setMinimised(!props.minimised)}}>
            <div className="label-content">
                {props.title}
            </div>
            <Button 
                disabled={!props.minimisable} 
                className="btn btn-xs collapse-btn"
                aria-hidden={props.hidden}
                aria-label={`${props.title} ${props.minimised ? "expand" : "collapse"}`} >
                {props.minimisable ? <Icons.ChevronLeft className="panel-label-arrow" /> : null}
            </Button>
        </div>
    )
}

export function PanelHeader (props: any) {

    const generateClasses = () => {
        var classes = "panel-header"
        classes = props.className ? classes.concat(" "+props.className) : classes
        classes = props.hidden || props.minimised ? classes.concat(" panel-child-hidden") : classes
        return classes
    }

    return (
        <div className={generateClasses()} aria-hidden={props.minimised} tabIndex={props.minimised ? -1 : null}>
            <div className="panel-header-content">
                {props.loadingContent ? (
                    <img className="loading-placeholder shimmer" src="/images/header placeholder.png" alt="list placeholder" />
                ) : props.children}
            </div>

            <hr/>
        </div>
    )
}

export function PanelBody (props: any) {
    
    const generateClasses = () => {
        var classes = "panel-body"
        classes = props.className ? classes.concat(" "+props.className) : classes
        classes = props.hidden || props.minimised ? classes.concat(" panel-child-hidden") : classes
        return classes
    }

    return (
        <div className={generateClasses()} aria-hidden={props.minimised} tabIndex={props.minimised ? -1 : null} >
            {!props.loadingContent 
                ? props.children 
                : (
                <div className="panel-columns">
                    <div><img className="loading-placeholder shimmer" src="/images/detail placeholder.png" alt="list placeholder" /></div>
                    <div><img className="loading-placeholder shimmer" src="/images/detail placeholder.png" alt="list placeholder" /></div>
                </div>
            )}
        </div>
    )
}