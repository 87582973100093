import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import Help from "../../../misc/Help"
import { Button } from "@mui/material"
import { DisplayValue } from "../../.."
import { Theme } from "../../../misc/Theme"
import { store } from "../../../stores/Store"
import TruncText from "../../../misc/TruncText"


export default function ReportRequestForm ({enquiry}) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var ndsAccountOptions = []
    var customerTypeOptions = []
    var accountOptions = []


    // Create initial values
    var initialValues = {
        userReference: null
    }
    enquiry.reportOptions.forEach((option, i) => {
        initialValues[`${option.statusId}-${option.reportType}-${option.ndsAccountUid}-${option.customerType}-${option.accountUid}`] = false
        
        if (!ndsAccountOptions.find(ao => ao.value === option.ndsAccountUid)) {
            ndsAccountOptions.push({value: option.ndsAccountUid, label: option.ndsAccountName})
            initialValues["ndsAccountUid"] = option.ndsAccountUid
        }
        if (!customerTypeOptions.find(ao => ao.value === option.customerType)) {
            customerTypeOptions.push({value: option.customerType, label: DisplayValue("customer_type", option.customerType)})
            initialValues["customerType"] = option.customerType
        }
        if (!accountOptions.find(ao => ao.value === option.accountUid)) {
            accountOptions.push({value: option.accountUid, label: option.accountName})
            initialValues["accountUid"] = option.accountUid
        }
    })

    var formik = useFormik({

        initialValues: initialValues,
        validationSchema: Yup.object({}),

        onSubmit: async (values) => {

            setError("")
            setSubmitting(true)
            
            var valuesToSend = []
            
            Object.keys(values).forEach(checkboxName => {
                if (values[checkboxName] === true) {
                    valuesToSend.push({
                        statusId: checkboxName.split("-")[0],
                        reportType: checkboxName.split("-")[1],
                        enquiryUid: enquiry.uid,
                        userReference: values.userReference ? values.userReference : null,
                        ndsAccountUid: values["ndsAccountUid"],
                        accountUid: values["accountUid"],
                        customerType: values["customerType"],
                    })
                }
            })

            if (valuesToSend.length === 0) {
                setError("Please select one or more reports")
            } else {
                await Ajax.Report.Request(valuesToSend).then(() => 
                    store.AlertStore.Close())
                .catch((response) => 
                    setError(response.data.details))
            }
            
            setSubmitting(false)
        }
    })

    const handleSelectChange = (e) => {
        enquiry.reportOptions.forEach((option, i) => {
            formik.values[`${option.statusId}-${option.reportType}-${option.ndsAccountUid}-${option.customerType}-${option.accountUid}`] = false
        })
        formik.handleChange(e)
    }

    return (
        <form className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit} >

            <div className="form-header">
                <h2>Select Reports</h2>
            </div>

            <p>Please select the reports you would like to generate. </p>

            <div style={{display: "grid", gridGap: "20px", gridTemplateColumns: "200px 200px 200px 200px"}}>

                {ndsAccountOptions.length > 1 
                    ? <Field.Select formik label="NDS Account" name="ndsAccountUid" options={ndsAccountOptions} value={formik.values["ndsAccountUid"]} onChange={handleSelectChange} />
                    : null}
                {customerTypeOptions.length > 1 
                    ? <Field.Select formik label="Customer Type" name="customerType" options={customerTypeOptions} value={formik.values["customerType"]} onChange={handleSelectChange} />
                    : null}
                {accountOptions.length > 1 
                    ? <Field.Select formik label="Account" name="accountUid" options={accountOptions} value={formik.values["accountUid"]} onChange={handleSelectChange} />
                    : null}

                {store.AppStore.checkJwtRole("sysuser") 
                    ? <Field.Text formik label="User reference" name="userReference" value={formik.values["userReference"]} onChange={formik.handleChange} />
                    : null}

            </div>

            <div className="detail-section" style={{overflowY: "auto", maxHeight: "500px"}}>            
                <div className="section-table transparent">
                    {enquiry.reportOptions
                        .filter(option => option.ndsAccountUid === formik.values["ndsAccountUid"] && option.customerType === formik.values["customerType"] && option.accountUid === formik.values["accountUid"])
                        .map((option, i) => {
                            
                            var color = Theme.palette.secondary.main

                            if (option.outcome === "Y") {
                                color = Theme.palette.success.dark
                            } else if (option.outcome === "N") {
                                color = Theme.palette.error.main
                            }

                            var formikKey = `${option.statusId}-${option.reportType}-${option.ndsAccountUid}-${option.customerType}-${option.accountUid}`
                            
                            return (
                                <div key={i} className="section-row">
                                    <div className="section-cell" style={{width: "21px", marginRight: "10px"}}>
                                        {option.message ? (
                                            <Help>{option.message}</Help>
                                        ) : (
                                            <Field.Checkbox 
                                                formik
                                                name={formikKey} 
                                                value={formik.values[formikKey]} 
                                                onChange={formik.handleChange}/>
                                        )}
                                    </div>
                                    <div className="section-cell" style={{fontWeight: 500}}><TruncText text={DisplayValue("nationality", option.statusId)} /></div>
                                    <div className="section-cell" style={{width: "270px"}}>{DisplayValue("report_type", option.reportType)}</div>
                                    <div className="section-cell" style={{width: "90px", color: color}}>{DisplayValue("outcome", option.outcome)}</div>
                                </div>
                            )
                        })
                    }
                </div>
            </div>
            
            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button color="primary" className="btn btn-sm" onClick={() => store.AlertStore.Close()} >Cancel</Button>
                    
                <Button
                    type="submit"
                    color="primary"
                    variant="contained"
                    className="btn btn-sm">
                    Submit
                </Button>
            </div>

        </form>
    )
}