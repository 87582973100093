import React from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"


export default observer(function BreadCrumb () {

    return (
        <div className={`breadcrums ${store.AppStore.sessionState ? "disabled" : ""}`}>

            {store.NodeStore.breadcrumb.map((nodeRef, i) => (
                <React.Fragment key={i}>
                    {i > 0 ? <div className="breadcrumb-delimiter">/</div> : null}

                    <Link to={`/app/main/${nodeRef?.uid}`} aria-label={`Back to ${nodeRef?.name ? nodeRef?.name : nodeRef?.uid}`}>
                        {nodeRef?.name ? nodeRef?.name : nodeRef?.uid}
                    </Link>
                </React.Fragment>
            ))}

        </div>
    )
})