import {
    FormControl,
    FormControlLabel,
    FormLabel,
    Radio,
    RadioGroup
} from "@mui/material";
import { SelectFieldProps } from "./SelectField";


interface RadioSelectFieldProps extends SelectFieldProps {
    horizontal?: boolean;
}


export const RadioSelectField = ({
    name,
    value,
    label = "",
    options,
    onChange,
    warning = "",
    helpText = "",
    formik = false,
    disabled = false,
    autoFocus = false,
    horizontal = false,
}: RadioSelectFieldProps) => {

    return (
        <div className="form-field field-radio-select">
            <FormControl>

                {label ? <FormLabel>{label}</FormLabel> : null}

                <RadioGroup
                    name={name}
                    value={value}
                    style={{flexDirection: horizontal ? "row" : "column"}}
                    onChange={(e) => {
                        formik ? onChange(e) : onChange({name: name, value: e.target.value})
                    }} >

                    {options?.map((option, i) => (
                        <FormControlLabel
                            key={i}
                            value={option.value}
                            label={option.label}
                            disabled={disabled}
                            control={
                                // Apply autoFocus to the first option
                                <Radio inputProps={{autoFocus: i === 0 ? autoFocus : false}} />
                            } />
                    ))}
                </RadioGroup>

                <span className="warning">{warning}</span>

                {helpText ? <span className="help-text">{helpText}</span> : null}

            </FormControl>
        </div>
    )
}