import { IconButton, Menu, MenuItem, MenuList, Popover } from "@mui/material";
import React, { ReactElement } from "react";
import { ReactChild } from "react";
import { Icons } from "./Icons";


export interface InlineMenuItem {
    action?: Function;
    label: ReactChild;
    disabled?: boolean;
    hidden?: boolean;
}


interface InlineMenuProps {
    title?: string;
    opener?: ReactElement;
    disabled?: boolean;
    menuItems?: InlineMenuItem[];
    children?: ReactElement;
}


export default function InlineMenu ({
    title,
    menuItems,
    disabled = false,
    children = null,
    opener = (   
        <IconButton style={{padding: 0, margin: "auto 0"}} aria-label={title ? title : "Options"}>
            <Icons.More color="secondary" />
        </IconButton>
    )
}: InlineMenuProps) {

    const [anchorElement, setAnchorElement] = React.useState<null | HTMLElement>(null);

    const handleOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorElement(event.currentTarget);
    }

    const handleClose = () => {
        setAnchorElement(null)
    }

    var classes = disabled ? "disabled" : ""

    return (
        <>
            {React.cloneElement(
                opener, 
                {
                    onClick: handleOpen,
                    className: classes,
                    ...opener.props
                }
            )}

            <Popover
                open={!!anchorElement}
                onClose={handleClose}
                anchorEl={anchorElement}
                className="inline-menu"
            >
                {title ? <MenuItem disabled className="inline-menu-title">{title}</MenuItem>: null}

                {children ? children : null}

                {menuItems?.length ? (
                    <MenuList autoFocusItem>
                        {menuItems?.filter(item => item.hidden !== true).map(({
                            label, 
                            action = () => {},
                            disabled = false,
                        }: InlineMenuItem, i) => (
                            <MenuItem 
                                key={i} 
                                disabled={disabled}
                                className="inline-menu-item" 
                                onClick={() => {
                                    action()
                                    setAnchorElement(null)
                                }}>
                                {label}
                            </MenuItem>
                        ))}
                    </MenuList>
                ) : null}
            </Popover>
        </>
    )
}