import React from "react"
import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import ReportSection from "./ReportSection"
import { store } from "../../../../stores/Store"
import NextStepsSection from "./NextStepsSection"
import TruncText from "../../../../misc/TruncText"
import NodeOptionsMenu from "../../../../misc/NodeMenus"
import EnquiryStatusIndicator from "./EnquiryStatusIndicator"
import EnquiryDetailSection from "./EnquiryDetailSection"
import EnquirySettingsSection from "./EnquirySettingsSection"
import Panel, { PanelLabel, PanelHeader, PanelBody } from "../../../layout/Panel"
import EnquiryStatusSection from "./EnquiryStatusSection"
import { Enquiry } from "../../../../models/Enquiry"


export default observer(function EnquiryDetail () {

    var {enquiry} = store.EnquiryStore

    return (
        <Panel index={2} className="detail" width="100%" loadingContent={!enquiry}>

            <PanelLabel title="Enquiry details" />

            <PanelHeader>
                <h4>
                    <TruncText text={store.NodeStore.getDisplayName(enquiry)} />
                </h4>
                
                <EnquiryCta enquiry={enquiry} />
                
                <div style={{width: "100%", maxWidth: "300px"}}>
                    <EnquiryStatusIndicator enquiry={enquiry} />
                </div>

                <div style={{marginLeft: "auto"}}>
                    <NodeOptionsMenu node={enquiry} />
                </div>
            </PanelHeader>

            <PanelBody>
                <div className="panel-columns enquiry-detail">
                    <div>
                        <EnquiryDetailSection />
                        <EnquiryStatusSection enquiry={enquiry} />
                        <EnquirySettingsSection />
                    </div>

                    <div>
                        <ReportSection />
                        <NextStepsSection />
                    </div>
                </div>
            </PanelBody>

        </Panel>
    )
})

interface EnquiryCtaProps {
    enquiry: Enquiry;
}

const EnquiryCta = observer(({enquiry}: EnquiryCtaProps) => {

    const [loading, setLoading] = React.useState(false)

    const submit = () => {
        setLoading(true)
        store.EnquiryStore.progressEnquiry().finally(() => setLoading(false))
    }

    if (store.AppStore.sessionState) return <></>

    return (
        <Button disabled={loading || !!store.AppStore.sessionState} variant="contained" className="btn btn-xs enquiry-cta" onClick={() => submit()}>
            {store.EnquiryStore.actionLabel(enquiry)}
        </Button>
    )
})