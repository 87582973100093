import * as Yup from 'yup'
import { Alert } from '../..'
import { useFormik } from "formik"
import Field from "./fields/Field"
import { useStore } from "../../stores/Store"
import { Button, CircularProgress } from "@mui/material"


export default function LoginForm () {

    const {AppStore} = useStore()

    // fetch(process.env.REACT_APP_API_URL + "/..")

    var code_challenge = AppStore.getCookie("Aora-PKCE-code-challenge-cookie")

    const formik = useFormik({

        initialValues: {
            username: "",
            password: ""
        },

        validationSchema: Yup.object({
            username: Yup.string().required("Please give your username"),
            password: Yup.string().required("Please give your password")
        }),

        onSubmit: async (values, actions) => {
            
            return await AppStore.login(values).catch((response) => {
                
                var errors: any = {}
                
                if (response?.data?.errors) {
 
                    Object.keys(response?.data?.errors).forEach(key => {
                        errors[key] = String(response.data?.errors[key])
                    })

                } else if (response?.data?.detail) {
                    errors.password = response.data?.detail
                } else {
                    errors.password = "Something went wrong"
                }

                actions.setErrors(errors)
            })
        }
    })

    return (
        <form autoComplete="on" className="form form-login" onSubmit={formik.handleSubmit}>

            <h2 style={{fontWeight: 500}}>Sign in</h2>

            <div className="form-fields">
                <Field.Text
                    formik
                    name="username"
                    label="Username"
                    autoFocus={true}
                    autoComplete={true}
                    warning={formik.submitCount ? formik.errors.username : null}
                    value={formik.values.username}
                    onChange={formik.handleChange} />

                <Field.Text
                    formik
                    type="password"
                    name="password"
                    label="Password"
                    autoComplete={true}
                    warning={formik.submitCount ? formik.errors.password : null}
                    value={formik.values.password}
                    onChange={formik.handleChange} />
            </div>

            <div className="form-footer">

                <Button className="btn-md" disabled={formik.isSubmitting} type="submit" color="primary" variant="contained">
                    {!formik.isSubmitting ? "Sign in" : <CircularProgress />}
                </Button>

                {/* {code_challenge !== "sdf" ? (
                    <>
                        <Button
                            className="btn btn-md btn-oauth"
                            color="secondary"
                            // variant="contained"
                            href={"https://accounts.google.com/o/oauth2/v2/auth?"+
                                "client_id=43337068224-056bioiuanp5n6naqbjq4lvkqdeps3nh.apps.googleusercontent.com&" +
                                "response_type=code&" +
                                `redirect_uri=${process.env.REACT_APP_ROOT_URL}&` +
                                `redirect_uri_mismatch=${process.env.REACT_APP_ROOT_URL}&` +
                                "scope=openid email&" +
                                "state=Google&" +
                                `code_challenge=${code_challenge}&` +
                                "code_challenge_method=S256"
                            }>
                            <img alt="M" src="https://upload.wikimedia.org/wikipedia/commons/thumb/5/53/Google_%22G%22_Logo.svg/2048px-Google_%22G%22_Logo.svg.png" />
                            Sign in with google
                        </Button>

                        <Button
                            className="btn btn-md btn-oauth"
                            color="secondary"
                            // variant="contained"
                            href={`https://login.microsoftonline.com/${process.env.REACT_APP_OAUTH_TENANT_ID}/oauth2/v2.0/authorize?` +
                            `client_id=${process.env.REACT_APP_OAUTH_CLIENT_ID}&` +
                            "response_type=code&" +
                            `redirect_uri=${process.env.REACT_APP_ROOT_URL}&` +
                            "scope=https%3A%2F%2Fgraph.microsoft.com%2Fopenid&" +
                            "state=Microsoft&" +
                            "code_challenge=" + code_challenge + "&" +
                            "code_challenge_method=S256"
                            }>
                            <img alt="M" src="https://cdn.pixabay.com/photo/2013/02/12/09/07/microsoft-80660_1280.png" />
                            Sign in with Microsoft
                        </Button>
                    </>
                ) : null} */}

                {/* <Button className="btn btn-xs" color="secondary" style={{textTransform: "none", marginTop: "10px"}} onClick={() => Alert({message: "Not yet functional, Sorry!"})}>Forgotten your password?</Button> */}
            </div>
        </form>
    )
}