import React from "react"
import * as Yup from 'yup'
import Ajax from "../../../Ajax"
import { useFormik } from "formik"
import Field from "../fields/Field"
import { Button } from "@mui/material"
import { DisplayDictionary } from "../../.."
import { store } from "../../../stores/Store"

// I separated the form into two components so that I could insert this form to be a section of another form.

export function UserCreateForm ({account}) {
    return <UserFormOnly
        title="Create User"
        includePassword={true}
        handleCancel={() => store.AlertStore.Close()}
        handleSubmit={(values) => Ajax.Node.Create("user", account.uid, values)} />
}

export function UserUpdateForm ({user}) {
    return <UserFormOnly
        title="Edit User"
        initialValues={user}
        handleCancel={() => store.AlertStore.Close()}
        handleSubmit={(values) => Ajax.Node.Edit("user", values)} />
}

export default function UserFormOnly ({
    title,
    handleSubmit,
    handleCancel,
    initialValues = {
        firstName: "",
        familyName: "",
        sex: "M",
        title: "",
        email: "",
        phone: "",
        username: "",
        password: "",
        // account: {uid: account.uid, name: account.label},
        isAccountManager: true,
        active: true
    },
    hidden = false,
    includePassword = false
}) {

    const [error, setError] = React.useState<string>("")
    const [submitting, setSubmitting] = React.useState(false)

    var formik = useFormik({

        initialValues: initialValues,

        validationSchema: Yup.object({
            firstName: Yup.string().required("This field is required"),
            familyName: Yup.string().required("This field is required"),
            email: Yup.string().matches(
                /\b[\w\.-]+@[\w\.-]+\.\w{2,4}\b/,
                "A valid email is required"
            ).required("This field is required"),
            phone: Yup.string().matches(
                /^\s*(?:\+?(\d{1,3}))?([-. (]*(\d{3})[-. )]*)?((\d{3})[-. ]*(\d{2,4})(?:[-.x ]*(\d+))?)\s*$/,
                "A valid phone number is required"
            ).required("This field is required"),
            username: Yup.string().required("This field is required"),
            password: includePassword ? Yup.string().required("This field is required") : null,
        }),

        onSubmit: async (values) => {

            setError("")
            setSubmitting(true)

            return await handleSubmit(values).then(() => {

                store.NodeStore.refreshFolderNode()
                store.AlertStore.Close()

            }).catch((response) => {

                setError(response.data?.detail ? response.data?.detail : "Something went wrong!")
                response.errors && formik.setErrors(response.errors)

            }).finally(() => setSubmitting(false))

        }
    })

    if (hidden) return null

    return (
        <form autoComplete="off" className={`form ${submitting ? "disabled" : ""}`} onSubmit={formik.handleSubmit}>

            <div className="form-header">
                <h3>{title}</h3>
                {/* <small>for {account.label}</small> */}
            </div>

            <div className="form-fields" style={{maxHeight: "60vh", overflowY: "scroll"}}>
                <Field.Text
                    formik
                    label="First name"
                    name="firstName"
                    value={formik.values.firstName}
                    warning={formik.submitCount && formik.errors.firstName ? formik.errors.firstName : false}
                    onChange={formik.handleChange} autoFocus={true} />

                <Field.Text
                    formik
                    label="Last name"
                    name="familyName"
                    value={formik.values.familyName}
                    warning={formik.submitCount && formik.errors.familyName ? formik.errors.familyName : false}
                    onChange={formik.handleChange}  />

                <Field.Select
                    formik
                    label="Title"
                    name="title"
                    value={formik.values.title}
                    warning={formik.submitCount && formik.errors.title ? formik.errors.title : false}
                    onChange={formik.handleChange} options={DisplayDictionary("title", true)}  />

                <Field.Text
                    formik
                    label="Email"
                    name="email"
                    value={formik.values.email}
                    warning={formik.submitCount && formik.errors.email ? formik.errors.email : false}
                    onChange={formik.handleChange}  />

                <Field.Text
                    formik
                    label="Phone"
                    name="phone"
                    value={formik.values.phone}
                    warning={formik.submitCount && formik.errors.phone ? formik.errors.phone : false}
                    onChange={formik.handleChange}  />
                <Field.Text
                    formik
                    label="Username"
                    name="username"
                    value={formik.values.username}
                    warning={formik.submitCount && formik.errors.username ? formik.errors.username : false}
                    onChange={formik.handleChange} autoComplete={false} />

                {includePassword
                    ? <Field.Text
                        formik
                        label="Password"
                        name="password"
                        value={formik.values.password}
                        warning={formik.submitCount && formik.errors.password ? formik.errors.password : false}
                        onChange={formik.handleChange}
                        type="password"
                        autoComplete={false} />
                    : null}

                <Field.Checkbox
                    formik
                    label="Account manager"
                    name="isAccountManager"
                    value={formik.values.isAccountManager}
                    warning={formik.submitCount && formik.errors.isAccountManager ? formik.errors.isAccountManager : false}
                    onChange={formik.handleChange} />
                    
                <Field.Checkbox
                    formik
                    label="Active"
                    name="active"
                    value={formik.values.active}
                    warning={formik.submitCount && formik.errors.active ? formik.errors.active : false}
                    onChange={formik.handleChange} />
            </div>

            {error ? <div className="form-message warning">{error}</div> : null}

            <div className="form-buttons">
                <Button
                    color="primary"
                    className="btn btn-sm"
                    onClick={() => handleCancel()}>
                    Cancel
                </Button>
                <Button
                    color="primary"
                    variant="contained"
                    className="btn btn-sm"
                    type="submit">
                    Submit
                </Button>
            </div>

        </form>
    )
}