import React from "react"
import Report from "./Report"
import { Button } from "@mui/material"
import { observer } from "mobx-react-lite"
import Field from "../../../form/fields/Field"
import { store } from "../../../../stores/Store"
import { AlertModal, DisplayValue } from "../../../.."
import TicketRequestForm from "../../../form/TicketRequestForm"


export default observer(function ReportSection () {

    const [showOldVersions, setShowOldVersions] = React.useState(false)

    const {enquiryReports, enquiryReportsCurrentOnly, groupByNationality, checkForPendingReports} = store.ReportStore
    const invalidCurrentReport = !!enquiryReportsCurrentOnly.find(r => ["X", "E", "F"].includes(r.fileStatus))
    const groupedReports = showOldVersions ? groupByNationality(enquiryReports) : groupByNationality(enquiryReportsCurrentOnly)

    const serverVersionDate = new Date(store.AppStore.sessionInfo.versionDate)
    const enquiryLastRun = new Date(store.EnquiryStore.enquiry?.lastStarted)
    const suggestRerun = enquiryLastRun < serverVersionDate

    const openTicketForm = () => {
        var description = `The reports in question can be found at this link:\n${window.location.href}`
        AlertModal({body: <TicketRequestForm title="Error with reports" description={description} />})
    }

    React.useEffect(() => {
        checkForPendingReports()
    }, [])

    return (
        <div className="detail-section">
            <div className="section-header">
                <div className="header-content">
                    <h5>Reports</h5>
                    {enquiryReports.length > enquiryReportsCurrentOnly.length
                        ? <Field.Checkbox label="Show old versions" value={showOldVersions} warning={false} onChange={() => setShowOldVersions(!showOldVersions)} />
                        : null}
                </div>
            </div>

            {Object.keys(groupedReports).map((nationalityId, i) => (
                <div key={i} className="detail-section">
                    <div className="section-subheader">
                        <h5>{DisplayValue("nationality", nationalityId)}</h5>
                    </div>

                    <div className="section-table">
                        {groupedReports[nationalityId].map((report, i) => <Report key={i} report={report} /> )}
                    </div>
                </div>
            ))}

            {!enquiryReports.length ? (
                <div className="section-subheader">
                    <h5>There are no reports to show</h5>
                </div>
            ) : null}

            {invalidCurrentReport && suggestRerun ? (
                <div className="detail-section-clear">
                    <h5 className="warning">You have reports which have errors or have failed. This enquiry has not been run since the last server update, re-running the enquiry may fix the problem.</h5>
                    <br/>
                    <Button className="btn btn-sm" variant="contained" onClick={() => store.EnquiryStore.rerun()}>Re-run enquiry</Button>
                </div>
            ) : null}

            {invalidCurrentReport && !suggestRerun ? (
                <div className="detail-section-clear">
                    <h5 className="warning">You have reports which have errors or have failed. Notify support about the problem so we can get it fixed as soon as possible.</h5>
                    <br/>
                    <Button className="btn btn-sm" variant="contained" onClick={() => openTicketForm()}>Contact support</Button>
                </div>
            ) : null}
        </div>
    )
})