import Ajax from '../Ajax'
import { store } from './Store'
import { makeAutoObservable } from 'mobx'
import { InformationRequest } from '../models/InformationRequest'


export class QuestionStore {
    
    informationRequest: InformationRequest | null = null;
    initialisationForm: "CREATE_PERSON" | null = null
    serverValidationMessage: string = "";
    provisionCount: number = 0;
    loading: boolean = true;

    constructor() {
        makeAutoObservable(this)
    }

    initForm = (initialisationForm: "CREATE_PERSON" | null) => {
        
        this.initialisationForm = initialisationForm
        
        if (initialisationForm !== null) {
            store.AppStore.currentPanel = 1
        }
    }

    handleRunInfo = (info: string) => {
        this.provisionCount = this.provisionCount + parseInt(info)
    }

    receiveInformationRequest = async (question: InformationRequest) => {

        this.initForm(null)
        this.provisionCount = 0
        this.serverValidationMessage = ""
        this.informationRequest = new InformationRequest(question)

        if (question.locatorPath?.nodeRefs?.length > 0) {
            
            var personUid = question.locatorPath.nodeRefs.at(0).uid // Should always be to the subject of the question
            var sectionUid = question.locatorPath.nodeRefs.at(-1).uid
            
            // Navigate to question subject if there is a valid UID            
            if (personUid && personUid.split("_").at(-1) !== "0" && store.AppStore.stateAction?.includes("Running")) {
                store.NodeStore.navigateNode(personUid)
            }
            
            // Highlight section
            if (sectionUid !== personUid) {
                store.NodeStore.highlightSection(sectionUid)
            }
        }

        if (!store.NodeStore.loading) {
            store.NodeStore.refreshFolderNode()
        }

        store.AppStore.currentPanel = 1
        this.loading = false
    }

    submitAnswer = async (values, certainties) => {

        this.loading = true
        this.serverValidationMessage = ""
        store.NodeStore.highlightSectionUid = ""

        await Ajax.Message.Respond(values, certainties).then(() => {
            if (store.AppStore.stateAction === "Editing") {
                store.NodeStore.refreshLeafNode()
            }
        }).catch((response) => {
            this.serverValidationMessage = response.data.detail
            this.loading = false
        })

        if (store.AppStore.stateAction.includes("Editing")) {
            this.informationRequest = null
        }
    }
}