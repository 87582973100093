import { Alert } from '..'
import { store } from './Store'
import { history } from '../index'
import { makeAutoObservable } from 'mobx'
import { ErrorNode, NodeReference } from '../models/Interfaces';
import { InformationRequest } from '../models/InformationRequest';
import Ajax from '../Ajax';


const fakeQuestions = [
    {
        statement: "Investors of WickedBuild REIT",
        title: "Please provide the details of the new relationship",
        type: "RELATIONSHIP"
    },
    {
        statement: "Investors of WickedBuild REIT",
        title: "Please provide the details of the new relationship",
        type: "RELATIONSHIP"
    },
    {
        statement: "Investors of WickedBuild REIT",
        title: "Please provide the details of the new relationship",
        type: "RELATIONSHIP"
    }
]

var relationshipDisplayDict = {
    "INV": "Investor",
    "OWN": "Owner"
}


export class TaxNodeStore {
    
    folderNode: any | null = tree;
    leafNode: any | null = null;
    breadcrumb: NodeReference[] = [{uid: tree.uid, name: tree.label}];
    loadingLeafNode: boolean = false
    loading: boolean = false
    relationshipDisplayDict = relationshipDisplayDict

    // Enquiry stuff
    informationRequest: any | null = null;
    setInformationRequest = (index: 0|1|2 = null) => {
        var ir = index !== null ? fakeQuestions[index] : null
        this.informationRequest = ir
    }

    constructor() {
        makeAutoObservable(this)
    }

    get rootNodeUid() {
        return store.AppStore.sessionInfo.parentAccount.uid
    }

    get getFolderChildren () {
        
        if (this.folderNode === null) {
            return []
        }

        // Order cases alphabetically
        if (this.folderNode.typeName?.includes("Folder")) {
            return [...this.folderNode.childrenObjects].sort((a, b) => {
                return a.label.localeCompare(b.label)
            })
        }

        return this.folderNode.childrenObjects
    }

    private setFolderNode = (node: any) =>
        this.folderNode = node

    private setLeafNode = (node: any) => {
        console.log("Leaf node: ", node)
        this.leafNode = node
    }
    
    //////////////////////////////////////////////////////////////////////////////

    step: "UPLOAD"|"EVALUATE"|"REPORT" = "UPLOAD";
    spreadSheetName: string;
    evaluationResults: any;

    index = () => {
        Ajax.Tax.Index().then((response) => {
            Alert({message: response.data?.detail.toString()})
        })
    }

    upload = (values: any) => {
        var spreadSheetName
        var formData = new FormData()
        
        values.files.forEach(file => {
            formData.append("files", file)
            spreadSheetName = file.name
            return 
        })

        Ajax.Tax.Upload(formData).then(() => {
            this.step = "EVALUATE"
            this.spreadSheetName = spreadSheetName
        }).catch((response) => {
            Alert({message: response.data?.detail.toString()})
        })
    }

    evaluate = async (goal: string) => {
        
        await Ajax.Tax.Evaluate(this.spreadSheetName, goal).then((response) => {
            console.log(response)
            this.evaluationResults = response.data
            this.step = "REPORT"
        }).catch((response) => {
            Alert({message: response.data?.detail.toString()})
        })
    }

    report = (reportUid: string, fileName: string) => {
        Ajax.Tax.Report(reportUid, fileName).catch((response) => {
            Alert({message: response.data?.detail.toString()})
        })
    }

    cancel = () => {
        this.step = "UPLOAD"
        this.spreadSheetName = null
        this.evaluationResults = null
    }

    //////////////////////////////////////////////////////////////////////////////

    refreshFolderNode = () => 
        this.selectNode(this.folderNode ? this.folderNode.uid : this.rootNodeUid)

    refreshLeafNode = () => 
        this.selectNode(this.leafNode.uid)

    navigateNode = async (uid: string = null, focusPanel: boolean = true) => {

        if (!uid || uid === this.rootNodeUid) { // Navigate to home if no args are given
            history.push("/app/tax")
        }

        // UID must be in '{type}_{id}' format
        if (uid?.split("_", 2).length < 2) { return }

        var type = uid?.split("_")[0]
        
        switch (type) {
            case "TREE":
            case "TAXENQUIRY":
            case "FOLDER":
            case "CASE":
                if (!this.loading) { // Disable navigation when already loading node
                    var leafNodeUid = this.leafNode?.uid ? this.leafNode?.uid : ""
                    history.push(`/app/tax/${uid}/${leafNodeUid}`)
                }
                return
            
            case "COMPANY":
            case "RELATION-GROUP":
            case "USER":
            case "ENTITY":
            case "PERSON":
                if (!this.loading && !this.loadingLeafNode) { // Disable navigation when already loading nodes
                    var folderNodeUid = this.folderNode?.uid ? this.folderNode.uid : this.rootNodeUid
                    history.push(`/app/tax/${folderNodeUid}/${uid}`)
                }
                return
        }
    }


    handleUri = async (params: any) => {

        // Handle leaf node
        if (!("leafnode_uid" in params)) {
            this.setLeafNode(null)
            this.loadingLeafNode = false
        } else {
            this.selectNode(`${params.leafnode_uid}`, !!store.AppStore.sessionState !== true)
        }

        // Handle folder node
        if (!("folder_uid" in params)) {
            // this.breadcrumb = [this.rootNodeUid]
            this.selectNode(this.rootNodeUid)
        
        } else if ("folder_uid" in params && this.folderNode?.uid !== params.folder_uid) {
            this.selectNode(`${params.folder_uid}`, !!store.AppStore.sessionState !== true)
        }
    }

    searchChildren = (node, uid) => {

        if (node.uid === uid) return node

        var result = null

        node.childrenObjects?.forEach((child) => {
            if (result === null) {
                result = this.searchChildren(child, uid)
            }
        })

        return result
    }
    
    private updateBreadcrumb = (node) => {
        
        var rootNodeRef = {uid: tree.uid, name: tree.label}

        // Look for existing reference
        var existingReference = this.breadcrumb.find((bc, i) => bc.uid === node.uid)
        var existingReferenceIndex = this.breadcrumb.indexOf(existingReference)

        this.breadcrumb = existingReference
            ? [...this.breadcrumb.slice(0, existingReferenceIndex), { uid: node.uid, name: node.label }] // Trunc back to existing reference
            : [...this.breadcrumb, { uid: node.uid, name: node.label }] // Append new reference

        // Check if the first reference is to the parent acccount, if not then insert a reference at the start.
        // Once GetNodePath is implemented, improve this so that the whole path to the root node is inserted.
        if (rootNodeRef.uid !== this.breadcrumb[0].uid) {
            this.breadcrumb = [rootNodeRef, ...this.breadcrumb]
        }
    }


    findNodeInTree = (uid) => {
        return this.searchChildren(tree, uid)
    }


    // All node navigation should be done by changing the URI and then calling selectNode from handleUri
    private selectNode = async (uid: string, focusPanel: boolean = true) => {
        
        var node: any

        // UID must be in '{type}_{id}' format
        if (uid?.split("_", 2).length < 2) { return } 

        var type = uid?.split("_")[0]

        switch (type) {
            case "TREE":
            case "TAXENQUIRY":
            case "FOLDER":
            case "CASE":

                this.loading = true
                
                node = this.findNodeInTree(uid)
                if (node === null) {
                    node = this.generateErrorNode(uid, 404)
                }
                this.setFolderNode(node)
                this.updateBreadcrumb(node)
                
                this.loading = false
                return Promise.resolve(node)

            case "RELATION-GROUP":
            case "COMPANY":
            case "ENTITY":
            case "PERSON":
            case "ENQUIRY":

                this.loadingLeafNode = true
                this.setLeafNode(null)

                if (focusPanel) {
                    store.AppStore.currentPanel = 2
                }

                node = this.findNodeInTree(uid)
                if (node === null) {
                    node = this.generateErrorNode(uid, 404)
                }
                this.setLeafNode(node)
                this.loadingLeafNode = false
                return Promise.resolve(node)

            default:
                // Alert({ message: `Unexpected type: ${uid}` })
                return Promise.reject()

        }
    }

    private generateErrorNode = (uid: string, responseCode: number) => {

        var label = "Server error"
        if (responseCode === 404) {
            label = "Not found"
        } else if (responseCode === 403) {
            label = "Unauthorised"
        }

        var node = new ErrorNode({
            uid: uid,
            typeName: "Error",
            label: label,
            detail: responseCode
        })

        return node
    }
}



const tree = {
    label: "REIT Demo",
    uid: "TREE_1",
    childrenObjects: [
        {
            label: "2022",
            uid: "FOLDER_3",
            childrenObjects: [
                {
                    label: "Case 1",
                    uid: "CASE_1",
                    childrenObjects: [
                        {
                            label: "Enquiry 1",
                            uid: "ENQUIRY_1",
                            entity_type: "ENQUIRY",
                        },
                        {
                            label: "Overview",
                            uid: "RELATION-GROUP_1",
                            childrenObjects: [
                                {
                                    uid: "RELATIONSHIP_0",
                                    relationship_type: "INV",
                                    from: "COMPANY_2",
                                    to: "COMPANY_1",
                                    amount: 0.09
                                }, 
                                {   
                                    uid: "RELATIONSHIP_1",
                                    relationship_type: "INV", 
                                    from: "COMPANY_3",
                                    to: "COMPANY_1",
                                    amount: 0.07
                                }, 
                                {   
                                    uid: "RELATIONSHIP_2",
                                    relationship_type: "INV", 
                                    from: "COMPANY_4",
                                    to: "COMPANY_1",
                                    amount: 0.08
                                }, 
                                {   
                                    uid: "RELATIONSHIP_3",
                                    relationship_type: "INV", 
                                    from: "COMPANY_5",
                                    to: "COMPANY_1",
                                    amount: 0.08
                                }, 
                                {   
                                    uid: "RELATIONSHIP_4",
                                    relationship_type: "INV", 
                                    from: "COMPANY_6",
                                    to: "COMPANY_1",
                                    amount: 0.08
                                }, 
                                {   
                                    uid: "RELATIONSHIP_5",
                                    relationship_type: "INV", 
                                    from: "COMPANY_7",
                                    to: "COMPANY_1",
                                    amount: 0.06
                                }, 
                                {   
                                    uid: "RELATIONSHIP_6",
                                    relationship_type: "INV", 
                                    from: "COMPANY_8",
                                    to: "COMPANY_1",
                                    amount: 0.08
                                }, 
                                {   
                                    uid: "RELATIONSHIP_7",
                                    relationship_type: "INV", 
                                    from: "COMPANY_9",
                                    to: "COMPANY_1",
                                    amount: 0.1
                                }, 
                                {   
                                    uid: "RELATIONSHIP_8",
                                    relationship_type: "INV", 
                                    from: "COMPANY_10",
                                    to: "COMPANY_1",
                                    amount: 0.08
                                }, 
                                {   
                                    uid: "RELATIONSHIP_9",
                                    relationship_type: "INV", 
                                    from: "COMPANY_11",
                                    to: "COMPANY_1",
                                    amount: 0.07
                                }, 
                                {   
                                    uid: "RELATIONSHIP_10",
                                    relationship_type: "INV", 
                                    from: "COMPANY_12",
                                    to: "COMPANY_1",
                                    amount: 0.07
                                }, 
                                {   
                                    uid: "RELATIONSHIP_11",
                                    relationship_type: "INV", 
                                    from: "COMPANY_13",
                                    to: "COMPANY_1",
                                    amount: 0.06
                                }, 
                                {   
                                    uid: "RELATIONSHIP_12",
                                    relationship_type: "INV", 
                                    from: "COMPANY_14",
                                    to: "COMPANY_5",
                                    amount: 0.35
                                }, 
                                {   
                                    uid: "RELATIONSHIP_13",
                                    relationship_type: "INV", 
                                    from: "COMPANY_15",
                                    to: "COMPANY_5",
                                    amount: 0.3
                                }, 
                                {   
                                    uid: "RELATIONSHIP_14",
                                    relationship_type: "INV", 
                                    from: "COMPANY_16",
                                    to: "COMPANY_5",
                                    amount: 0.35
                                }, 
                                {   
                                    uid: "RELATIONSHIP_15",
                                    relationship_type: "INV", 
                                    from: "COMPANY_16",
                                    to: "COMPANY_6",
                                    amount: 1
                                }, 
                                {   
                                    uid: "RELATIONSHIP_16",
                                    relationship_type: "INV", 
                                    from: "COMPANY_17",
                                    to: "COMPANY_7",
                                    amount: 1
                                }, 
                                {   
                                    uid: "RELATIONSHIP_17",
                                    relationship_type: "INV", 
                                    from: "COMPANY_20",
                                    to: "COMPANY_8",
                                    amount: 1
                                }, 
                                {   
                                    uid: "RELATIONSHIP_18",
                                    relationship_type: "INV", 
                                    from: "COMPANY_21",
                                    to: "COMPANY_10",
                                    amount: 1
                                }, 
                                {   
                                    uid: "RELATIONSHIP_19",
                                    relationship_type: "INV", 
                                    from: "COMPANY_22",
                                    to: "COMPANY_14",
                                    amount: 1
                                }, 
                                {   
                                    uid: "RELATIONSHIP_20",
                                    relationship_type: "INV", 
                                    from: "COMPANY_15",
                                    to: "COMPANY_16",
                                    amount: 0.25
                                }, 
                                {   
                                    uid: "RELATIONSHIP_21",
                                    relationship_type: "INV", 
                                    from: "COMPANY_17",
                                    to: "COMPANY_16",
                                    amount: 0.75
                                }, 
                                {   
                                    uid: "RELATIONSHIP_22",
                                    relationship_type: "INV", 
                                    from: "COMPANY_17",
                                    to: "COMPANY_18",
                                    amount: 0.7
                                }, 
                                {   
                                    uid: "RELATIONSHIP_23",
                                    relationship_type: "INV", 
                                    from: "COMPANY_19",
                                    to: "COMPANY_18",
                                    amount: 0.3
                                }, 
                                {   
                                    uid: "RELATIONSHIP_24",
                                    relationship_type: "INV", 
                                    from: "COMPANY_18",
                                    to: "COMPANY_20",
                                    amount: 0.4
                                }, 
                                {   
                                    uid: "RELATIONSHIP_25",
                                    relationship_type: "INV", 
                                    from: "COMPANY_21",
                                    to: "COMPANY_20",
                                    amount: 0.6
                                },
                                {
                                    uid: "RELATIONSHIP_26",
                                    relationship_type: "OWN",
                                    from: "COMPANY_1",
                                    to: "COMPANY_26",
                                    ammount: 1,
                                },
                                {
                                    uid: "RELATIONSHIP_27",
                                    relationship_type: "OWN",
                                    from: "COMPANY_1",
                                    to: "COMPANY_27",
                                    ammount: 0.8,
                                },
                                {
                                    uid: "RELATIONSHIP_28",
                                    relationship_type: "OWN",
                                    from: "COMPANY_28",
                                    to: "COMPANY_27",
                                    ammount: 0.2,
                                },
                            ]
                        },
                        {
                            label: "Great Red Storage REIT",
                            uid: "COMPANY_1",
                            entity_id: 1,
                            entity_type: "PRIN",
                            new_or_existing: "New",
                            reit_type: "GR",
                            incorporated: "UK",
                            incorporation_reg_num: "3625198",
                            cmc: "UK",
                            tax_residence_places: "None",
                            oeic: "FALSE",
                            shares_admit_for_trading_date: "FALSE",
                            shares_exchange: "FALSE",
                            shares_listed_from_date: "FALSE",
                            shares_last_traded_date: "FALSE",
                            accounting_periods: [
                                {"ac_num": 1, from: "06/01/2023", to: "05/01/2024", "p2_antiavoidance": null, "p5_antiavoidance": null, "p6_antiavoidance": null, "general_antiavoidance": null},
                                {"ac_num": 2, from: "06/01/2024", to: "05/01/2025", "p2_antiavoidance": null, "p5_antiavoidance": null, "p6_antiavoidance": null, "general_antiavoidance": null},
                                {"ac_num": 3, from: "06/01/2025", to: "05/01/2026", "p2_antiavoidance": null, "p5_antiavoidance": null, "p6_antiavoidance": null, "general_antiavoidance": null},
                                {"ac_num": 4, from: "06/01/2026", to: "05/01/2027", "p2_antiavoidance": null, "p5_antiavoidance": null, "p6_antiavoidance": null, "general_antiavoidance": null},
                            ],
                            num_ord_share_classes: "1",
                            ord_share_fixed: "TRUE",
                            ord_share_other_rights: "FALSE",
                            preference_shares: "TRUE",
                            pref_voting_shares: "FALSE",
                            pref_voting_contingent: "FALSE",
                            pref_voting_exercise: "FALSE",
                            pref_new_consideration: "TRUE",
                            pref_excessive_repayment: "FALSE",
                            pref_market_repayment: "FALSE",
                            pref_acquisition_rights: "FALSE",
                            pref_dividend_rights: "TRUE",
                            dividends_excessive: "FALSE",
                            dividends_fixed: "FALSE",
                            dividends_index_link: "TRUE",
                            divdends_variation: "TRUE",
                            dividends_financial_difficulties: "TRUE",
                            dividends_reasonable_variations: "FALSE",
                        },

                        {
                            label: "AfterCare LLP",
                            entity_id: 2,
                            uid: "COMPANY_2",
                            entity_type: "LLP",
                            institutional_investor_type: "LPCIS",
                            institutional_investor_type_notes: "AfterCare is a limited liability partnership which serves as a collective investment scheme.",
                            intended_investors_specified: "TRUE",
                            deterrent_specification: "FALSE",
                            deterrent_terms: "FALSE",
                            fund_at_capacity: "FALSE",
                            capacity_fixed: "FALSE",
                            capacity_exhausted: "FALSE",
                            marketing_sufficient: "TRUE",
                        },

                        {
                            label: "Hospice on the Shore",
                            entity_id: 3,
                            uid: "COMPANY_3",
                            entity_type: "CHAR",
                            institutional_investor_type: "CHAR",
                            institutional_investor_type_notes: "Its charities number is 1145022",
                        },

                        {
                            label: "Herbert Brothers LLP",
                            entity_id: 4,
                            uid: "COMPANY_4",
                            entity_type: "LLP",
                            institutional_investor_type: "LPCIS",
                            intended_investors_specified: "TRUE",
                            deterrent_specification: "FALSE",
                            deterrent_terms: "FALSE",
                            fund_at_capacity: "FALSE",
                            capacity_fixed: "FALSE",
                            capacity_exhausted: "FALSE",
                            marketing_sufficient: "TRUE",
                            has_status: "UKTR_P1_GDO",
                        },

                        {    
                            label: "Barretts Ltd",
                            entity_id: 5,
                            uid: "COMPANY_5",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "HagenHomes Ltd",
                            entity_id: 6,
                            uid: "COMPANY_6",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "McFarlands",
                            entity_id: 7,
                            uid: "COMPANY_7",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "HomeWright",
                            entity_id: 8,
                            uid: "COMPANY_8",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "Mercutor Insurance",
                            entity_id: 9,
                            uid: "COMPANY_9",
                            entity_type: "CLS",
                            institutional_investor_type: "LONG",
                            institutional_investor_type_notes: "Mercutor has a letter from the PRA granting it permission to act in the long-term insurance business, dated 06/06/2011 (their reference no JL99320611",
                        }, 
                        {    
                            label: "Argus Ltd",
                            entity_id: 10,
                            uid: "COMPANY_10",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "Heartworks",
                            entity_id: 11,
                            uid: "COMPANY_11",
                            entity_type: "CHAR",
                            institutional_investor_type: "CHAR",
                            institutional_investor_type_notes: "Heartworks' charity number is 0345567.",
                        }, 
                        {    
                            label: "Tom Watkins",
                            entity_id: 12,
                            uid: "PERSON_12",
                            entity_type: "PER",
                        }, 
                        {    
                            label: "Mandy Kingley",
                            entity_id: 13,
                            uid: "PERSON_13",
                            entity_type: "PER",
                        }, 
                        {    
                            label: "Obduras Partners",
                            entity_id: 14,
                            uid: "COMPANY_14",
                            entity_type: "PTNR",
                            body_corporate: "FALSE",
                        }, 
                        {    
                            label: "Van Tork Ltd",
                            entity_id: 15,
                            uid: "COMPANY_15",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "Portcullis Incorporated",
                            entity_id: 16,
                            uid: "COMPANY_16",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "GIC",
                            entity_id: 17,
                            uid: "COMPANY_17",
                            entity_type: "FUND",
                            institutional_investor_type: "SOV",
                            institutional_investor_type_notes: "GIC has a letter from HMRC's Privileges & Immunities, International committee confirming its sovereign exemption from direct UK taxes dated 03/11/2013.",
                        }, 
                        {    
                            label: "Castellan Ltd",
                            entity_id: 18,
                            uid: "COMPANY_18",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "Caspar Renson",
                            entity_id: 19,
                            uid: "PERSON_19",
                            entity_type: "PER",
                        }, 
                        {    
                            label: "Dennerco",
                            entity_id: 20,
                            uid: "COMPANY_20",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "King Unit Trust",
                            entity_id: 21,
                            uid: "COMPANY_21",
                            entity_type: "FUND",
                            institutional_investor_type: "TUT",
                            institutional_investor_type_notes: "The FCA confirmed by a letter dated 03/04/2015 that the Fund was approved as a unit trust (their reference no DR34-04/15",
                        }, 
                        {    
                            label: "Solomon LPP",
                            entity_id: 22,
                            uid: "COMPANY_22",
                            entity_type: "LPP",
                            institutional_investor_type: "LPCIS",
                            institutional_investor_type_notes: "Solomon LLP has a letter from HMRC's Privileges & Immunities, International committee confirming a sovereign exemption from direct UK taxes dated 30/10/2009.",
                            intended_investors_specified: "TRUE",
                            deterrent_specification: "TRUE",
                            deterrent_terms: "TRUE",
                            fund_at_capacity: "TRUE",
                            capacity_fixed: "TRUE",
                            capacity_exhausted: "TRUE",
                            marketing_sufficient: "TRUE",
                            
                        }, 
                        {    
                            label: "Barclays",
                            entity_id: 23,
                            uid: "COMPANY_23",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "The Ursine Group",
                            entity_id: 24,
                            uid: "COMPANY_24",
                            entity_type: "FUND",
                        }, 
                        {    
                            label: "HSBC",
                            entity_id: 25,
                            uid: "COMPANY_25",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "Great Red Storage Holdings",
                            entity_id: 26,
                            uid: "COMPANY_26",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "Great Red Residential ",
                            entity_id: 27,
                            uid: "COMPANY_27",
                            entity_type: "CLS",
                        }, 
                        {    
                            label: "Owen Rider",
                            entity_id: 28,
                            uid: "PERSON_28",
                            entity_type: "PER",
                        },
                    ]
                }
            ]
        },
        
        {
            label: "2021",
            uid: "FOLDER_2",
            childrenObjects: []
        }
    ]
}