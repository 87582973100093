import Logo from "./Logo";

export default function BrandFiller () {

    return (
        <div aria-hidden="true" className="brand-filler">
            <div className="brand-filler-content">
                <Logo height="45px" color="#c0c0c0" />
                <p>Legal Opinion Automation</p>
            </div>
        </div>
    )
}