import { Link } from "react-router-dom"
import { DisplayValue } from "../../.."
import { observer } from 'mobx-react-lite'
import { store } from '../../../stores/Store'
import { Icons, TypeIcon } from "../../../misc/Icons"
import { format } from "date-fns"



export default observer(function FolderPanelListItem ({node}: any) {    
    
    var isStateSubject: boolean = store.AppStore.stateSubject === node.uid
    var classes = "panel-list-item"
    classes = isStateSubject ? `${classes} state-subject` : classes
    classes = node.uid === store.NodeStore.leafNode?.uid ? `${classes} selected` : classes
    var displayName = node.label ? node.label : node.uid
    var isLeafNode = [
        "Aora.Nationality.Data.PersonNode",
        "Aora.Platform.Data.EnquiryNode",
        "Aora.Platform.Data.UserNode"
    ].indexOf(node.typeName) > -1
    
    var displayType = store.NodeStore.getDisplayType(node.typeName)
    var link = !isLeafNode
        ? `/app/main/${node.uid}`
        : `/app/main/${store.NodeStore.folderNode.uid}/${node.uid}`

    return (
        <Link
            to={link}
            className={classes}
            aria-label={`${displayType}: ${displayName}`}
        >
            <TypeIcon node={node} />
            <ListItemLabel node={node} />
            {isLeafNode && <Icons.ArrowRight />}
        </Link>
    )
})



function ListItemLabel ({node}: any) {    

    var isStateSubject: boolean = store.AppStore.stateSubject === node.uid    

    switch (node.typeName) {
        
        case "Aora.Platform.Data.EnquiryNode":
            
            var enquiry = node
            
            return (
                <label>
                    <div style={{display: "grid", paddingRight: "8px"}}>
                        <span style={{fontSize: "11px"}}>{store.NodeStore.getDisplayName(node)}</span>
                        <span className="trunc-ellipsis" style={{width: "auto", fontSize: "9px", color: "grey"}}>{store.EnquiryStore.subjectName(enquiry)}</span>
                    </div>

                    {isStateSubject
                        ? <div className="status-indicator">{store.AppStore.stateAction}</div>
                        : <div className="status-indicator" style={{color: store.EnquiryStore.color(enquiry)}}>{DisplayValue("enquiry_status", enquiry.status)}</div>}
                </label>
            )

        case "Aora.Nationality.Data.PersonNode":

            var dob = new Date(node.dateOfBirth)
            var dob_str = dob > new Date("1000-01-01") ? format(dob, "yyyy/MM/dd") : ""

            return (
                <label>
                    <div style={{display: "grid", paddingRight: "8px"}}>
                        <span style={{fontSize: "11px"}}>{store.NodeStore.getDisplayName(node)}</span>
                        <span className="trunc-ellipsis" style={{width: "auto", fontSize: "9px", color: "grey"}}>{dob_str} - {node.countryOfBirth}</span>
                    </div>

                    {isStateSubject ? <div className="status-indicator">{store.AppStore.stateAction}</div> : null}
                </label>
            )
        default:
            return (
                <label>
                    <div className="trunc-ellipsis">{store.NodeStore.getDisplayName(node)} {node.role?.join(", ")}</div>
                    {isStateSubject ? <div className="status-indicator">{store.AppStore.stateAction}</div> : null}
                </label>
            )
    }
}