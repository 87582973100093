import React from "react"


export default function TruncText ({text}) {
    
    const [toolTip, setToolTip] = React.useState("")
    const [toolTipAnchor, setToolTipAnchor] = React.useState({x: 0, y: 0})
    const ref = React.useRef<HTMLElement>()

    const revealTruncatedText = (e) => {
        
        var fullWidth = ref.current.offsetWidth
        var targetRect = e.target.getBoundingClientRect()

        // console.log("Reveal", e.target.offsetWidth < fullWidth, e.target.offsetWidth, fullWidth, ref)

        if (e.target.offsetWidth < fullWidth) {
            setToolTip(text)
            setToolTipAnchor({x: targetRect.left + 10, y: targetRect.bottom})
        }
    }

    const hideTruncatedText = (e) => {
        
        setToolTip("")
        setToolTipAnchor({x: -1000, y: -1000})
    }

    return (
        <>
            <div className="trunc-revealer" style={{
                display: toolTip ? "block" : "none",
                left: toolTipAnchor.x,
                top: toolTipAnchor.y
            }}>{toolTip}</div>

            <span ref={ref} id="trunc-text-full-width" className="trunc-ellipsis" style={{opacity: "0", top: "-1000", zIndex: -100, position: "fixed", width: "fit-content"}}>{text}</span>

            <span className="trunc-ellipsis" onMouseLeave={(e) => hideTruncatedText(e)} onMouseEnter={(e) => revealTruncatedText(e)}>{text}</span>
        </>
    )
}