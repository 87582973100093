import Ajax from "../../../../Ajax"
import { Alert, AlertModal } from "../../../.."
import { store } from "../../../../stores/Store"
import { Button, CircularProgress } from "@mui/material"
import CreateEnquiryForm from "../../../form/enquiry/CreateEnquiryForm"
import React from "react"
import { observer } from "mobx-react-lite"

interface EntityCtaProps {
    entity: any;
}

export default observer(function EntityCta ({entity}: EntityCtaProps) {

    const [loading, setLoading] = React.useState(false)

    if (loading) {
        return <CircularProgress style={{width: "14px", height: "14px", margin: 0}} />
    }

    if (!store.AppStore.sessionState) {
        return (
            <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
                {store.NodeStore.isEditing ? (
                    <>
                        <Button
                            disabled={true}
                            variant="contained" 
                            className="btn btn-xs">
                            Save
                        </Button>
                        <Button
                            className="btn btn-xs"
                            onClick={() => {
                                store.NodeStore.isEditing = false
                            }} >
                            Cancel
                        </Button>
                    </>
                ) :(
                    <>
                        <Button
                            variant="contained" 
                            className="btn btn-xs"
                            onClick={() => AlertModal({body: <CreateEnquiryForm entityUid={entity.uid} />, size: "auto"})} >
                            New Enquiry
                        </Button>
                        
                        <Button
                            className="btn btn-xs"
                            onClick={async () => {
                                store.NodeStore.isEditing = true
                            }} >
                            Edit
                        </Button>
                    </>
                )}
            </div>
        )
    }

    if (store.AppStore.sessionState?.includes("Editing " + entity.uid)) {
        return (
            <div style={{display: "flex", alignItems: "center", gap: "16px"}}>
                <Button
                    disabled={!entity.editsPending || !!store.QuestionStore.informationRequest}
                    variant="contained" 
                    className="btn btn-xs"
                    onClick={async () => {
                        setLoading(true)
                        await Ajax.Entity.SaveEdit().catch((response) => Alert({message: response.data.detail, color: "error"}))
                        setLoading(false)
                    }} >
                    Save
                </Button>
                <Button
                    className="btn btn-xs"
                    disabled={!!store.QuestionStore.informationRequest}
                    onClick={async () => store.NodeStore.cancelEdit()} >
                    Cancel
                </Button>
            </div>
        )
    }

    return <></>
})