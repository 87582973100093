import { Alert, AlertModal } from '..'
import Ajax from '../Ajax'
import { store } from './Store'
import { history } from '../index'
import { makeAutoObservable } from 'mobx'
import { ErrorNode, NodeReference } from '../models/Interfaces';
import { AxiosResponse } from 'axios'


export class NodeStore {
    
    folderNode: any | null = null;
    leafNode: any | null = null;
    loadingLeafNode: boolean = true
    loading: boolean = true
    breadcrumb: NodeReference[] = [];
    isEditing: boolean = false;
    editsMade: boolean = false;
    highlightSectionUid: string = null

    constructor() {
        makeAutoObservable(this)
    }

    get rootNodeUid() {
        return store.AppStore.sessionInfo.parentAccount.uid
    }

    get getFolderChildren () {
        
        if (this.folderNode === null) {
            return []
        }

        // Order cases alphabetically
        if (this.folderNode.typeName?.includes("Folder")) {
            return [...this.folderNode.childrenObjects].sort((a, b) => {
                return a.label.localeCompare(b.label)
            })
        }

        if (this.folderNode.typeName?.includes("Case")) {
            return [...this.folderNode.childrenObjects].sort((a, b) => {
                return b.dateOfBirth?.localeCompare(a.dateOfBirth)
            })
        }

        return this.folderNode.childrenObjects
    }

    setFolderNode = (node: any) => {
        this.folderNode = node
        this.isEditing = false
    }

    setLeafNode = (node: any) => {
        this.leafNode = node
                
        // if (node?.typeName === "Aora.Platform.Data.EnquiryNode") {
        //     store.EnquiryStore.setEnquiry(node)
        // }

        if (node?.typeName === "Aora.Nationality.Data.PersonNode" || node?.typeName === "Aora.Platform.Data.EnquiryNode") {
            if (node.caseNode && !window.location.href.includes(node.caseNode.uid)) {
                this.navigateNode(node.caseNode.uid)
            } 
        }
    }

    reset = () => {
        this.leafNode = null
        this.loadingLeafNode = false
        this.folderNode = null
        this.loading = true
        this.breadcrumb = []
    }

    updateEntity = async (action: Function) => {
        if (store.QuestionStore.informationRequest) {
            return Alert({message: "You cannot update two attributes at once", color: "error"})
        }
        try {
            await Ajax.Entity.BeginEdit(store.NodeStore.leafNode.uid)
            await action()
        }
        catch (e) {
            Alert({message: "Your update failed"})
        }
        this.isEditing = false
    }

    editEntity = async (action: Function) => {
        if (store.QuestionStore.informationRequest) {
            return Alert({message: "You cannot edit two attributes at once", color: "error"})
        }
        try {
            await Ajax.Entity.BeginEdit(store.NodeStore.leafNode.uid)
            await action()
            this.editsMade = true
        }
        catch (e) {
            Alert({message: "Your edit failed"})
        }
        this.isEditing = false
    }

    cancelEdit = async () => {
        try {
            if (store.QuestionStore.informationRequest) {
                await Ajax.Entity.CancelEdit()
                store.QuestionStore.informationRequest = null
            }
            setTimeout(() => Ajax.Message.Cancel(), 600)
        }
        catch (e) {
            Alert({message: "Cancelling failed"})
        }
        this.isEditing = false
    }

    refreshFolderNode = () => 
        this.selectNode(this.folderNode ? this.folderNode.uid : this.rootNodeUid)

    refreshLeafNode = () => 
        this.selectNode(this.leafNode.uid)

    getBreadcrumb = async () => 
        await Ajax.Node.Path(this.folderNode.uid, this.rootNodeUid)
            .then((response: any) => this.breadcrumb = response.data.nodes)


    navigateNode = async (uid: string = null, focusPanel: boolean = true) => {

        if (!uid || uid === this.rootNodeUid) { // Navigate to home if no args are given
            history.push("/app/main")
        }

        // UID must be in '{type}_{id}' format
        if (uid?.split("_", 2).length < 2) { return }

        var type = uid?.split("_")[0]
        
        switch (type) {
            
            case "ACCOUNT":
            case "FOLDER":
            case "CASE":
                if (!this.loading) { // Disable navigation when already loading node
                    var leafNodeUid = this.leafNode?.uid ? this.leafNode?.uid : ""
                    history.push(`/app/main/${uid}/${leafNodeUid}`)
                }
                return
            
            case "USER":
            case "ENTITY":
            case "PERSON":
            case "ENQUIRY":
                if (!this.loading && !this.loadingLeafNode) { // Disable navigation when already loading nodes
                    var folderNodeUid = this.folderNode?.uid ? this.folderNode.uid : this.rootNodeUid
                    history.push(`/app/main/${folderNodeUid}/${uid}`)
                }
                return
        }
    }


    handleUri = (params: any) => {

        // Handle leaf node
        if (!("leafnode_uid" in params)) {
            this.setLeafNode(null)
            this.loadingLeafNode = false
        } else {
            this.selectNode(`${params.leafnode_uid}`, !!store.AppStore.sessionState !== true)
        }

        // Handle folder node
        if (!("folder_uid" in params)) {
            this.selectNode(this.rootNodeUid)
        } else if ("folder_uid" in params && this.folderNode?.uid !== params.folder_uid) {
            this.selectNode(`${params.folder_uid}`, !!store.AppStore.sessionState !== true)
        }
    }


    // All node navigation should be done by changing the URI and then calling selectNode from handleUri
    private selectNode = async (uid: string, focusPanel: boolean = true) => {
        
        var node: any

        // UID must be in '{type}_{id}' format
        if (uid?.split("_", 2).length < 2) { return } 

        var type = uid?.split("_")[0]

        switch (type) {
            case "ACCOUNT":
            case "FOLDER":
            case "CASE":

                this.loading = true

                await Ajax.Node.Get(uid).then((response) => {
                    node = response.data
                }).catch((response) => {
                    node = this.generateErrorNode(uid, response)
                })
                
                this.setFolderNode(node)
                this.updateBreadcrumb(node)
                store.AppStore.setPageTitle(this.getDisplayName(node))
                
                this.loading = false
                return Promise.resolve(node)

            case "USER":
            case "ENTITY":
            case "PERSON":
            case "ENQUIRY":

                this.loadingLeafNode = true
                this.setLeafNode(null)
                store.ReportStore.setReports([])

                if (focusPanel) {
                    store.AppStore.currentPanel = 2
                }

                await Ajax.Node.Get(uid).then((response) => {
                    node = response.data
                }).catch((response) => {
                    node = this.generateErrorNode(uid, response)
                })
                
                this.setLeafNode(node)

                this.loadingLeafNode = false
                return Promise.resolve(node)

            default:
                Alert({ message: `Unexpected type: ${uid}` })
                return Promise.reject()

        }
    }
    

    private generateErrorNode = (uid: string, response: AxiosResponse) => {

        var label = "Server error"
        if (response?.status === 404) {
            label = "Not found"
        } else if (response?.status === 403) {
            label = "Unauthorised"
        }

        var node = new ErrorNode({
            uid: uid,
            typeName: "Error",
            label: label,
            detail: response?.data?.detail
        })

        return node
    }


    highlightSection = (sectionUid: string = null) => {
        this.highlightSectionUid = sectionUid
    }


    private updateBreadcrumb = (node) => {
        
        var displayName = this.getDisplayName(node)
        
        // Look for existing reference
        var existingReference = this.breadcrumb.find((bc, i) => bc.uid === node.uid)
        var existingReferenceIndex = this.breadcrumb.indexOf(existingReference)

        this.breadcrumb = existingReference
            ? [...this.breadcrumb.slice(0, existingReferenceIndex), { uid: node.uid, name: displayName }] // Trunc back to existing reference
            : [...this.breadcrumb, { uid: node.uid, name: displayName }] // Append new reference

        // Check if the first reference is to the parent acccount, if not then insert a reference at the start.
        // Once GetNodePath is implemented, improve this so that the whole path to the root node is inserted.
        if (store.AppStore.sessionInfo?.parentAccount?.uid && store.AppStore.sessionInfo?.parentAccount?.uid !== this.breadcrumb[0].uid) {
            this.breadcrumb = [store.AppStore.sessionInfo.parentAccount, ...this.breadcrumb]
        }
    }


    getDisplayName = (node: any) => {

        switch (node?.typeName) {
            case "Error":
            case "Aora.Platform.Data.UserNode":
            case "Aora.Platform.Data.AccountNode":
            case "Aora.Nationality.Data.PersonNode":
                return node.label
            case "Aora.Platform.Data.FolderNode":
            case "Aora.Platform.Data.CaseNode":
                return node.name
            case "Aora.Platform.Data.EnquiryNode":
                var id = node.uid.split("_").length === 2 ? node.uid.split("_")[1] : "???"
                return "Enquiry " + id
            case undefined:
                return "No node"
            default:
                return node.uid
        }
    }

    getDisplayType = (typeName) => {

        switch (typeName) {
            case "Aora.Platform.Data.AccountNode":
                return "Organisation"
            case "Aora.Platform.Data.UserNode":
                return "User"
            case "Aora.Platform.Data.FolderNode":
                return "Folder"
            case "Aora.Platform.Data.CaseNode":
                return "Case"
            case "Aora.Nationality.Data.PersonNode":
                return "Person"
            case "Aora.Platform.Data.EnquiryNode":
                return "Enquiry"
            case "Aora.Platform.Data.ReportNode":
                return "Report"
            case undefined:
                return "Unknown"
            default:
                return "Node"
        }
    }

    scrollToDetailNode = (nodeUid: string) => {

        var detailPanel = document.getElementById("panel-2")
        var scrollEl = detailPanel.getElementsByClassName("panel-body")

        var elements = document.querySelectorAll(`[data-uid='${nodeUid}']`)

        if (elements.length && scrollEl.length) {
            var attrElement = elements[0].getBoundingClientRect();
            scrollEl[0].scrollTo(0, attrElement.top)
            console.log(attrElement.top)
        }
    }

}