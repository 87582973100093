import React from 'react';
import { Line } from "react-chartjs-2";
import { observer } from 'mobx-react-lite'
import { store } from '../../../stores/Store';


export default observer(function OneDay() {

    const { loading, avMemory, avTime, avSession, avPostgres, avCpu, getPollingDaily } = store.MaintStore

    React.useEffect(() => {
        getPollingDaily()
    }, [])

    const labels1 = avTime;

    const data1 = {
        labels: labels1,
        datasets: [
            {
                label: "memory usage",
                backgroundColor: "rgb(100, 99, 132)",
                borderColor: "rgb(100, 99, 132)",
                fill: false,
                data: avMemory,
            },
            {
                label: "cpu usage",
                backgroundColor: "rgb(10, 99, 132)",
                borderColor: "rgb(10, 99, 132)",
                // data: [connections[0]],
                fill: false,
                data: avCpu,
            },
        ],
    };


    const data_session1 = {
        labels: labels1,
        datasets: [
            {
                label: "open sessions",
                backgroundColor: "rgb(60, 70, 200)",
                borderColor: "rgb(60, 70, 200)",
                fill: false,
                data: avSession
            },
            {
                label: "postgres connections",
                backgroundColor: "rgb(100, 30, 100)",
                borderColor: "rgb(100, 30, 100)",
                fill: false,
                data: avPostgres
            }
        ]
    };


    const options = {
        scales: {
            y: {
                min: 0.00,
                max: 100.00,
                title: {
                    display: true,
                    text: 'Percentage Used',
                    font: {
                        size: 15,
                        family: 'sans-serif',
                    },
                },

            },
            x: {
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: 15,
                        family: 'sans-serif'
                    }
                }
            }
        },
        plugins: {
            sort: {
                enable: true
            }
        }
    };

    const options_session = {
        scales: {
            y: {
                min: 0.00,
                title: {
                    display: true,
                    text: 'Open Sessions',
                    font: {
                        size: 15,
                        family: 'sans-serif',
                    },
                },

            },
            x: {
                title: {
                    display: true,
                    text: 'Time',
                    font: {
                        size: 15,
                        family: 'sans-serif'
                    }
                }
            }
        }
    };

    if (loading) return <p>Loading...</p>

    return (
        <div>
            <Line data={data1} options={options} />
            <Line data={data_session1} options={options_session} />
        </div>
    )
})