import './App.scss';
import App from './App';
import ReactDOM from 'react-dom';
import { Theme } from './misc/Theme';
import { Router } from 'react-router-dom';
import { SnackbarProvider } from 'notistack';
import BrandFiller from './misc/BrandFiller';
import { createBrowserHistory } from 'history';
import ErrorBoundary from './misc/ErrorBoundary';
import { store, storeContext } from './stores/Store';
import { ThemeProvider } from '@mui/material/styles';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';



export const history = createBrowserHistory()

export const DisplayDictionary = store.AppStore.DisplayDictionary

export const DisplayValue = store.AppStore.DisplayValue

export const AlertModal = store.AlertStore.AlertModal

export const CloseModal = store.AlertStore.Close

export const Alert = store.AlertStore.Alert


export default function Root () {

	// var tracks = filterPlaylist()
	
	return (
		<ThemeProvider theme={Theme}>
			<ErrorBoundary>
				<SnackbarProvider maxSnack={5}>
					<LocalizationProvider dateAdapter={AdapterDateFns}>
						<storeContext.Provider value={store}> 
							<Router history={history}>  
								
								<App />
								<BrandFiller />
								<store.AlertStore.RenderAlertModals />
								
							</Router>
						</storeContext.Provider>    
					</LocalizationProvider>
				</SnackbarProvider>	
			</ErrorBoundary>
		</ThemeProvider>
	)
}


ReactDOM.render(<Root />, document.getElementById('root'));

