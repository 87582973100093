import { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { store } from '../../../stores/Store';
import ViewBanner from '../../layout/ViewBanner';
import Panel, { PanelBody, PanelHeader, PanelLabel } from '../../layout/Panel';
import ErrorBoundary from '../../../misc/ErrorBoundary';
import MaintCharts from './MaintCharts';
import { Button, IconButton, Tab, Tabs } from "@mui/material"
import React from 'react';
import TenMins from './TenMins';
import OneDay from './OneDay';
import OneYear from './OneYear';
import { Icons } from '../../../misc/Icons';


export default function MaintView () {

    store.AppStore.setPageTitle("Aora - Dashboard")
    const [tab, setTab] = React.useState("10 mins")

    return (
        <ErrorBoundary>
            <ViewBanner title="Maintenance Dashboard" />
            <div className="view view-maint">
                <Panel index={2} width="100%" className="report-management-table-panel">
                    <PanelLabel />
                    
                    <PanelHeader>
                        <div style={{display: "flex", alignItems: "center"}}>
                            <h3>{tab}</h3>
                            <IconButton className="btn btn-sm" onClick={() => {
                                tab === "10 mins" && store.MaintStore.getPolling()
                                tab === "1 day" && store.MaintStore.getPollingDaily()
                                tab === "1 year" && store.MaintStore.getPollingYearly()
                            }}>
                                <Icons.Refresh />
                            </IconButton>
                        </div>

                        <Tabs
                            value={tab}
                            textColor="secondary"
                            indicatorColor="primary" >
                            <Tab onClick={() => setTab("10 mins")} value="10 mins" label="10 mins" />
                            <Tab onClick={() => setTab("1 day")} value="1 day" label="1 day" />
                            <Tab onClick={() => setTab("1 year")} value="1 year" label="1 year" />
                        </Tabs>
                    </PanelHeader>
                    
                    <PanelBody>
                        {tab === "10 mins" ? <TenMins /> : null}
                        {tab === "1 day" ? <OneDay /> : null}
                        {tab === "1 year" ? <OneYear /> : null}
                    </PanelBody>
                </Panel>
            </div>
        </ErrorBoundary>
    )
}