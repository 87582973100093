import React from "react"
import DemoForms from "./DemoForms"
import DemoTheme from "./DemoTheme"
import DemoModals from "./DemoModals"
import Help from "../../../misc/Help"
import DemoLanguage from "./DemoLanguage"
import { Button, Tab, Tabs } from "@mui/material"
import { Icons } from "../../../misc/Icons"
import { store } from "../../../stores/Store"
import ViewBanner from "../../layout/ViewBanner"
import Panel, { PanelBody, PanelHeader, PanelLabel } from "../../layout/Panel"



export default function UiDemoView() {

    store.AppStore.setPageTitle("Aora - UI Demo")
    const [tab, setTab] = React.useState("language")

    return (
        <>
            <ViewBanner title="]v[" />

            <div className="view view-settings">

                <Panel index={2} className="detail" width="100%" minimisable={false}>

                    <PanelLabel title="UI Demo" />

                    <PanelHeader>

                        <div style={{display: "flex", justifyContent: "space-between", width: "100%", alignItems: "center"}}>
                            <h3>User Interface Demo</h3>

                            <div style={{display: "flex", alignItems: "center"}}>
                                <Help>
                                    <h3>Page description</h3>
                                    <p>This is a page for demonstrating all of our UI components so they can be tested and scrutinised independently.</p>
                                </Help>
                                <Tabs
                                    value={tab}
                                    // centered={true}
                                    textColor="secondary"
                                    indicatorColor="primary"
                                    >
                                    <Tab onClick={() => setTab("language")} value="language" label="Langauge" />
                                    <Tab onClick={() => setTab("forms")} value="forms" label="Form components" />
                                    <Tab onClick={() => setTab("modals")} value="modals" label="Modals" />
                                    <Tab onClick={() => setTab("theme")} value="theme" label="Theme" />
                                    <Tab onClick={() => setTab("icons")} value="icons" label="Icons" />
                                    <Tab onClick={() => setTab("buttons")} value="buttons" label="Buttons" />
                                </Tabs>
                            </div>
                        </div>

                    </PanelHeader>

                    <PanelBody>
                        {tab === "forms" ? <DemoForms /> : null}
                        {tab === "modals" ? <DemoModals /> : null}
                        {tab === "theme" ? <DemoTheme /> : null}
                        {tab === "language" ? <DemoLanguage /> : null}
                        {tab === "icons" ? (
                            <div className="settings-section">
                                <a target="_blank" rel="noreferrer" href="https://mui.com/material-ui/material-icons/">Choose more Icons here</a>

                                <div className="icons">
                                    {Object.values(Icons).map((Icon, i) => (
                                        <div className="icons-item" key={i}>
                                            <Icon color="primary" />
                                            <p>{Object.keys(Icons)[i]}</p>
                                        </div>
                                    ))}
                                </div>
                            </div>
                        ) : null}
                        
                        {tab === "buttons" ? (
                            <div className="settings-section">
                                {["", "btn btn-md", "btn btn-sm", "btn btn-xs"].map((classes, i) => (
                                    <>
                                        <div style={{display: "flex", gap: "15px", padding: "10px 0"}}>
                                            {["primary", "secondary", "info", "error", "success"].map((color: "primary"|"secondary"|"info"|"error"|"success", i) => (
                                                <Button className={classes} variant="contained" color={color}>{color}</Button>
                                            ))}
                                        </div>
                                        <div style={{display: "flex", gap: "15px", padding: "10px 0"}}>
                                            {["primary", "secondary", "info", "error", "success"].map((color: "primary"|"secondary"|"info"|"error"|"success", i) => (
                                                <Button className={classes} variant="text" color={color}>{color}</Button>
                                            ))}
                                        </div>
                                    </>
                                ))}
                            </div>
                        ) : null}
                    </PanelBody>

                </Panel>
            </div>
        </>
    )
}