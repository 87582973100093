import * as Yup from 'yup'
import { Button } from "@mui/material"
import { useFormik } from "formik"
import { observer } from "mobx-react-lite"
import { store } from "../../../stores/Store"
import { PanelWrapper } from "./TaxDetailPanel"
import Field from '../../form/fields/Field'
import { Icons } from '../../../misc/Icons'
import Help from '../../../misc/Help'
import { Theme } from '../../../misc/Theme'


export default observer(function TaxEnquiryDetail () {

    const {step, upload, evaluate, report} = store.TaxNodeStore

    return (
        <PanelWrapper>
            <div className="panel-columns">
                <div>
                    <Upload />
                    <Evaluate />
                    <Report />
                </div>
            </div>
        </PanelWrapper>
    )
})


const Upload = observer(function () {

    const {upload, spreadSheetName} = store.TaxNodeStore

    const formik = useFormik({

        initialValues: {
            files: []
        },

        validationSchema: Yup.object({
            files: Yup.mixed().test("files", "Please select at least one file", (files) => {
                return files.length > 0
            }),
        }),

        onSubmit: async (values, actions) => {

            await upload(values)
        }
    })

    var complete = !!spreadSheetName
    var classes = complete ? "disabled" : ""

    return (
        <div className={classes} style={{padding: "8px", boxShadow: "0 0 10px 0 #00000036", marginBottom: "20px", display: "grid", gap: "8px"}}>
            <div className="header" style={{margin: 0, alignItems: "center"}}>
                <h4>Upload a spreadsheet</h4>
                {complete ? <Icons.Tick style={{fontSize: "18px"}} color="success" />  : null}
            </div>
            <Field.File 
                formik
                name="files"
                disabled={formik.isSubmitting}
                onChange={formik.handleChange}
                value={formik.values.files}
                warning={formik.submitCount ? formik.errors.files : null} 
                label="Please click the button to select a file" />
            <Button disabled={formik.isSubmitting || complete} className="btn btn-sm" variant="contained" onClick={() => formik.handleSubmit()}>Upload</Button>
        </div>
    )
})

const Evaluate = observer(function () {
    
    const {evaluate, spreadSheetName, evaluationResults} = store.TaxNodeStore

    const formik = useFormik({
        initialValues: {
            inv_type: "CLOSE",
            inv_date: new Date()
        },
        onSubmit: async (values, actions) => await evaluate(values.inv_type)
    })

    var hide = !spreadSheetName
    var complete = !!evaluationResults
    var classes = complete ? "disabled" : ""

    if (hide) return <></>

    return (
        <div className={classes} style={{padding: "8px", boxShadow: "0 0 10px 0 #00000036", marginBottom: "20px", display: "grid", gap: "8px"}}>
            <div className="header" style={{margin: 0, alignItems: "center"}}>
                <h4>Evaluate</h4>
                {complete ? <Icons.Tick style={{fontSize: "18px"}} color="success" />  : null}
            </div>

            <p>Click bellow to run a full evaluation of your uploaded data</p>
            <div>
                <Field.Select formik name="inv_type" value={formik.values.inv_type} onChange={formik.handleChange} label="Investigation type" options={[
                    {value: "CLOSE", label: "Close Company"},
                    {value: "COMP_CONDS", label: "Company Conditions"},
                    {value: "PRB", label: "Property Rental Business"},
                ]} />
            </div>
            <Button disabled={formik.isSubmitting || complete} className="btn btn-sm" variant="contained" onClick={() => formik.handleSubmit()}>Evaluate</Button>

        </div>
    )
})

const Report = observer(function () {

    const {report, evaluationResults} = store.TaxNodeStore

    var hide = !evaluationResults

    if (hide) return <></>

    const {title, eval_log, msg, report_uid, eval_result} = evaluationResults


    return (
        <div style={{padding: "8px", boxShadow: "0 0 10px 0 #00000036", marginBottom: "20px", display: "grid", gap: "8px"}}>
            <div className="header" style={{margin: "0", alignContent: "flex-start"}}>
                <h4>{title}</h4>
                <Help content={
                    <h5 style={{color: eval_result[0] ? Theme.palette.success.dark : Theme.palette.error.dark}}>
                        {eval_result[0] ? "Success" : "Failed"}
                    </h5>
                }>
                    This outcome is {eval_result[1]}% certain
                </Help>
            </div>
            <p>{msg}</p>
            {/* {eval_log.map((logString) => <p style={{whiteSpace: "break-spaces"}}>{logString}</p>)} */}
            <Button className="btn btn-sm" variant="contained" onClick={() => report(report_uid, report_uid)}>Download Report</Button>
        </div>
    )
})