
import { AccountCreateForm, AccountEditForm } from "../components/form/admin/AccountForms";
import { UserCreateForm, UserUpdateForm } from "../components/form/admin/UserForms";
import UpdatePasswordForm from "../components/form/admin/UserPasswordUpdateForm";
import EnquiryOutcomeModal from "../components/modals/EnquiryOutcomeModal";
import AccountSelectForm from "../components/form/admin/AccountSelectForm";
import CaseDeleteForm from "../components/form/case/CaseDeleteForm";
import InlineMenu, { InlineMenuItem } from "./InlineMenu";
import { observer } from "mobx-react-lite";
import { store } from "../stores/Store";
import { AlertModal } from "..";
import Ajax from "../Ajax";
import { CaseCreateForm, CaseEditForm } from "../components/form/case/CaseForms";


interface NodeOptionsMenuProps {
    node: any;
    disabled?: boolean;
}


export default observer(function NodeOptionsMenu ({node, disabled = false}: NodeOptionsMenuProps) {

    if (!node) {
        return <InlineMenu disabled={true} menuItems={[]} />
    }
    
    return (
        <InlineMenu 
            disabled={!!store.AppStore.sessionState || disabled}
            title={store.NodeStore.getDisplayType(node.typeName) + " options"} 
            menuItems={NodeOptionsMenuItems(node)} />
    )
})


const NodeOptionsMenuItems = (node): InlineMenuItem[] => {

    const {ReportStore, AppStore, NodeStore} = store

    switch (node.typeName) {
        case "Aora.Platform.Data.AccountNode":
            return [
                {label: "Edit account", action: () => AlertModal({body: <AccountEditForm account={node} /> }), hidden: node.accountType === "admin"},
                {label: "Create account", action: () => AlertModal({body: <AccountCreateForm parentUid={node.uid} />}), hidden: node.accountType === "restricted"},
                {label: "Create standard account", action: () => AlertModal({body: <AccountCreateForm parentUid="ACCOUNT_-1" />}), hidden: node.accountType !== "admin"},
                {label: "Create user", hidden: !AppStore.checkJwtRole("account_manager"), action: () => AlertModal({body: <UserCreateForm account={node} /> })},
                {label: "Switch account", hidden: !AppStore.checkJwtRole("sysuser"), action: () => AlertModal({body: <AccountSelectForm /> })},
                {label: "Refresh", action: () => NodeStore.refreshFolderNode()}
            ]
        case "Aora.Platform.Data.UserNode":
            return [
                {label: "Change password", action: () => AlertModal({body: <UpdatePasswordForm user={node} />, size: "xs"})},
                {label: "Edit user", action: () => AlertModal({body: <UserUpdateForm user={node} />}), hidden: !store.AppStore.checkJwtRole("account_manager")}
            ]
        case "Aora.Platform.Data.FolderNode":
            return [
                {label: "Create case", action: () => AlertModal({body: <CaseCreateForm parentUid={node.uid.replace("FOLDER", "USER")} />})},
                {label: "Refresh", action: () => NodeStore.refreshFolderNode()}
            ]
        case "Aora.Platform.Data.CaseNode":
            return [
                {label: "Create person", action: () => store.QuestionStore.initForm("CREATE_PERSON")},
                {label: "Edit case", action: () => AlertModal({body: <CaseEditForm caseNode={node} />}) },
                {label: "Delete case", action: () => AlertModal({body: <CaseDeleteForm node={node} />}) },
                {label: "Refresh", action: () => NodeStore.refreshFolderNode()}
            ]
        
        case "Aora.Platform.Data.EnquiryNode":
            return [
                {label: store.EnquiryStore.actionLabel(node), action: () => store.EnquiryStore.progressEnquiry()},
                {label: "Re-run", action: () =>  Ajax.Enquiry.Run(node.uid), hidden: node.status !== "DEFINITIVE" || !node.valid},
                {label: "View outcome", action: () => AlertModal({body: <EnquiryOutcomeModal enquiry={node} />, width: "700px"})},
                // {label: "Automate", action: () => AlertModal({body: <CreateAutomationForm />, size: "md" })}
            ]
        
        case "Aora.Platform.Data.ReportNode":
            return [
                {label: "Download", action: () => ReportStore.download(node), disabled: ["R", "F"].includes(node.fileStatus)}, // Disabled if "Requested" or "Failed"
                {label: "Regenerate", action: () => ReportStore.regenerate(node), disabled: !AppStore.checkJwtRole("sysuser") },
                {label: "Mark as invalid", action: () => ReportStore.makeInvalid(node), disabled: !AppStore.checkJwtRole("sysuser") },
            ]
    
        default:
            return [
                {label: "No options", action: () => {}, disabled: true},
                {label: "Refresh", action: () => NodeStore.refreshFolderNode()}
            ]
    }
}