import React, { useEffect } from "react"
import Ajax from "../../../Ajax"
import Field from "../fields/Field"
import { store } from "../../../stores/Store"
import { observer } from "mobx-react-lite"


export default observer(function AccountSelectForm () {

    const [accountOptions, setAccountOptions] = React.useState(null)
    const [error, setError] = React.useState(null)

    const getAccounts = () => {

        setError(null)

        Ajax.Node.GetAccounts().then((response) => {
            setAccountOptions(response.data)
        }).catch((response) => {
            setError(response.data.detail)
        })
    }

    useEffect(() => {
        getAccounts()
    }, [])

    if (error) return <h4>{error}</h4>

    if (!accountOptions) return <h4>Loading accounts</h4>

    return (
        <form autoComplete="off" className={`form`}>

            <div className="form-header">
                <h2>Switch Account</h2>
            </div>

            <div className="form-fields">

                {accountOptions.length ? <p>Please select an account</p> : <p>There are no alternative accounts for you to switch to</p>}

                <Field.Select 
                    formik
                    onChange={(e) => {
                        console.log(e.target.value)
                        store.NodeStore.navigateNode(e.target.value)
                        store.AlertStore.Close()
                    }}
                    value={store.NodeStore.folderNode?.uid}
                    options={accountOptions.map(account => {
                        return {label: account.label, value: account.uid}
                    })}
                />
            </div>

        </form>
    )
})