import { Link } from "react-router-dom"
import { DisplayValue } from "../../.."
import { observer } from 'mobx-react-lite'
import { store } from '../../../stores/Store'
import { Icons, TypeIcon } from "../../../misc/Icons"
import { format } from "date-fns"


export default observer(function TaxNavigationListItem ({node}: any) {    

    var type = node.uid.split("_")[0]
    var isLeafNode = ["COMPANY", "RELATION-GROUP", "USER", "ENTITY", "PERSON"].includes(type)
    var link = !isLeafNode
        ? `/app/tax/${node.uid}`
        : `/app/tax/${store.TaxNodeStore.folderNode.uid}/${node.uid}`

    if (!store.TaxNodeStore.spreadSheetName && ["COMPANY", "RELATION-GROUP", "PERSON"].includes(type)) return <></>

    var classes = "panel-list-item"
        classes = node.uid === store.TaxNodeStore.leafNode?.uid ? `${classes} selected` : classes

    const LinkWrapper = ({children}) => {
        return (
            <Link
                to={link}
                className={classes}
            >
                <TypeIcon node={node} />
                <label>{children}</label>
                {isLeafNode && <Icons.ArrowRight />}
            </Link>
        )
    }

    switch (type) {
        default:
            return (
                <LinkWrapper>
                    <div className="trunc-ellipsis">{node.label}</div>
                </LinkWrapper>
            )
    }
})